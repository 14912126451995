.title {
	font-weight: 700;
	padding: 0.5em 0;
  font-size: 1.2em;
}

.list {
	padding: 0.5em;
}

.statement {
	padding: 0.5em 0;
  font-size: 1em;
}