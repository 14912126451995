@import "../Shared.module";

.container {
  position: relative;
  cursor: pointer;
  padding: 0;
  font-size: 16px;

  input {
    cursor: pointer;
  }

  .caret {
    margin: 0 10px;
  }

  .inputContainer {
    text-align: left;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  .dateWrapper {
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: relative;
    display: inline-block;

    :global(.react-datepicker__input-container) {
      /**
       * Hack because we cannot control the input container
       * using the component properties.
       */
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  .popperContainer {
    > div {
      z-index: 10;

      > div {
        > div {
          left: 50px !important;
        }
      }
    }
  }
}

.disabled {
  @extend .disabled;

  .container {
    cursor: not-allowed;
  }
}

[dir="rtl"] .container {
  > input {
    text-align: right;
  }
}
