@import "../../../styles/breakpoints";

.container {
  @media only screen and (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
    margin-top: 0;
    text-align: left;
  }
}

.body {
  max-height: 200px;
  margin-top: 30px;
  text-align: center;
}

.title {
  font-weight: 500;
  margin-top: 0;
	color: var(--header-text--color);

}

.subtitle {
  font-weight: 300;
  margin-top: 0;

	color: var(--header-text--color);

  > a {
    color: inherit;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  padding: 10px;
  margin: 15px 10px;
}

[dir="rtl"] .button {
  padding: 10px;
  margin: 5px 10px;
}