@import "~animate.css/animate.css";

/**
 * This file is only intended to be used as a style reset.
 * You should NEVER define component styling in here.
 */

html {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none;
}

#root {
  height: 100%;
  width: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background-color: var(--color-background);
  overflow: auto;
}

html::-webkit-scrollbar {
  display: none;
}
