@import "../../../../styles/breakpoints";

.adyen-checkout__dropin {
  width: 100%;
}

#dropin-container {
  width: 100%;
  margin: 29px 0 0;
  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }
}

.disabled {
  opacity: .3;
  pointer-events: none;
}

.adyen-checkout__payment-method {
  margin-bottom: 10px;
  background-color: var(--payment-method--background);
}

.adyen-checkout__payment-method--selected + .adyen-checkout__payment-method {
  background-color: var(--input--background);
  border-radius: 0;
}

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__header {
    flex-wrap: nowrap;
}

.adyen-checkout__payment-method__brands {
  height: auto;
  overflow: hidden;
  width: 100%;
  flex-wrap: nowrap;
}

[dir=rtl] .adyen-checkout__payment-method__brands {
  >span {
    margin-left: 4px;
    margin-right: 0;
  }
}

.adyen-checkout__payment-method__header {
  height: 50px;
}

.adyen-checkout__payment-method__name {
  color: var(--payment-method--color);
  padding: 0;
  font-size: 15px;
  font-weight: 300;
}

.adyen-checkout__payment-method {
  border: var(--input--border);
  border-radius: 0 0 0 0;
  background-color: var(--input--background);

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
}

.adyen-checkout__label__text {
  color: var(--text-secondary--color) !important;
  font-size: 15px;
  font-weight: 300;
}

.adyen-checkout__input {
  border: var(--input--border);
  border-radius: 4px;
  height: 50px;

  &:hover {
    border: var(--input--border);
  }
}

.input-field {
  padding-left: 30px;

}

.adyen-checkout__button--pay {
  background: var(--button-primary--background);
  border-radius: 3px;
  transition: box-shadow 0.3s ease-out;
}

.adyen-checkout__button--pay:hover {
  background: var(--button-primary--background);
  border-radius: 3px;
}

.adyen-checkout__dropdown__button {
  border: var(--input--border);
  border-radius: 4px;
  height: 50px;

  &:hover {
    border: var(--input--border);
  }
}

.adyen-checkout__dropdown__button__text {
  font-style: italic;
  font-weight: 100;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
}

.adyen-checkout__dropdown__list {
  z-index: 99;
}

.adyen-checkout__checkbox__label {
  font-size: 13px;
  font-weight: 200;
}

[dir="rtl"] .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2) {
  margin-left: 0;
  margin-right: 8px;
}

.adyen-checkout__label {
  color: var(--text-secondary--color) !important;
}

.adyen-checkout__label--focused {
  color: var(--text-secondary--color) !important;
}