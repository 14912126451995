@import "../../../../styles/breakpoints";

[dir="rtl"] .dropdown {
  :global .list {
    left: -10px;
    right: auto;

    @media only screen and (min-width: $breakpoint-small) {
      left: -50px;
    }
  }
}


[data-scrolling] .dropdown {
  svg {
    fill: var(--header__scrolling-dropdown-color);
  }
}
[data-scrolling] .icon {
  svg {
    fill: var(--header__scrolling-dropdown-color);
  }
}

[dir="rtl"] .dropdown {
	:global .label {
		margin-left: 0;
		margin-right: 10px;
	}
}

.dropdown {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
	background-color: #f2f2f2;
	border-radius: 30px; 
	padding: 15px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	
	&:hover {
		background-color: #e0e0e0;
	}
  @media only screen and (max-width: $breakpoint-medium) {
    position: static;
  }

  :global .container {
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    border-radius: 50%;
    height: 1em;
    background: var(--header-dropdown-background);
    color: var(--header-dropdown-color);
  }

	:global .label {
		margin-left: 10px;
  }

  :global .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 50px;
    right: -10px;
    left: auto;
    border-radius: 30px;
    overflow: hidden;
    max-height: 0;
    box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 5%);
    transition: all 0.2s ease-in;

    @media only screen and (min-width: $breakpoint-small) {
      right: -50px;
    }
  }

  :global .listActive {
    transition: all 0.5s ease-in !important;
    max-height: 500px !important;
    padding: 0;
  }

  :global .option {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    border-bottom: 0.5px solid rgba(211, 211, 211, 0.8);
    text-decoration: none;
    padding: 20px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 10px;
    }
  }
}

.dropdownContainer {
  display: flex;
  align-items: center;
  justify-content: stretch;
  grid-gap: 0 20px;
  gap: 0 20px;
  border-radius: 30px; 
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-width: 1.5em;
  flex-grow: 0;
  text-align: center;

  svg {
    display: block;
    max-height: 2em;
    fill: var(--header-dropdown-color);
  }
}