@import "../../../../styles/breakpoints";

.heading-incomplete {
  opacity: 0.3;
  display: unset;
  margin: 0;
  font-weight: 400;
  flex-grow: 1;

  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    text-align: center;
  }
}

.incomplete-flex-align-center {
  display: flex;
  justify-content: space-between;
  padding: 30px;
  align-items: center;
  @media only screen and (max-width: $breakpoint-medium) {
    flex-flow: column wrap;
  }
}

.line {
  justify-self: right;
  height: 60px;
  border: 1px solid #e8e8e8;
  margin: 0 30px;
  @media only screen and (max-width: $breakpoint-medium) {
    height: 2px;
    width: 100%;
    margin: 20px 0;
  }
}