@import "../../../styles/breakpoints";

.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &:first-child .nextIcon {
    display: none;
  }

  &:first-child .cardHeader {
    margin-top: -50px;
  }

  &:not(first-child) .cardHeader {
    margin-top: -100px;

    @media only screen and (max-width: $breakpoint-large) {
      margin-top: -50px;
    }
  }
}

.row {
  width: 100%;

  @media only screen and (max-width: $breakpoint-large) {
    width: 100%;
    margin-top: 50px;
  }
}

.dateTime {
  width: 120px;
  height: 120px;
  padding: 10px;
  position: relative;
  left: -115px;
  top: 70px;

  @media only screen and (max-width: $breakpoint-large) {
    float: right;
    position: relative;
    left: -20px;
    top: -35px;
    padding: 0;
    width: 110px;
    height: 30px;
  }
}

[dir="rtl"] .dateTime {
  left: 110px;

  @media only screen and (max-width: $breakpoint-large) {
    float: left;
    position: relative;
    left: 20px;
    top: -35px;
    padding: 0;
    width: 110px;
    height: 30px;
  }
}

.card {
  height: auto;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 30px;
  background-color: var(--summary-step-card--background);
  width: 100%;
  margin-top: -20px;
}

.date {
  text-align: center;
  font-weight: 400;
  margin: 0 auto;
  font-size: 1em;
  color: var(--summary-date-time--color);

  &:first-child {
    margin-top: 20px;
  }

  @media only screen and (max-width: $breakpoint-large) {
    text-align: right;
    &:first-child {
      margin-top: 0;
    }
  }
}

[dir="rtl"] .date {
  @media only screen and (max-width: $breakpoint-large) {
    text-align: left;
  }
}

.dateIcon {
  width: 25px;
  margin: 30px auto -20px;
  > svg {
    fill: var(--input-icon--color) !important;
  }

  @media only screen and (max-width: $breakpoint-large) {
    display: none;
  }
}

.stepIcon {
  width: 40px;
  margin: auto;
  > svg {
    fill: var(--input-icon--color) !important;
  }

  @media only screen and (max-width: $breakpoint-large) {
    margin: 0 auto 0 0;
  }
}

[dir="rtl"] .stepIcon {
  @media only screen and (max-width: $breakpoint-large) {
    margin: 0 0 0 auto;
  }
}

.nextIcon {
  width: 40px;
  margin: 0 auto 10px;
  transform: rotate(90deg);
  > svg {
    fill: var(--input-icon--color) !important;
  }

  @media only screen and (max-width: $breakpoint-large) {
    display: none;
  }
}

.cardHeader {
  width: 100%;
  text-align: center;
  margin: auto;
}

.cardBody {
  width: 100%;
  margin: 20px auto auto;
}