@import "../Shared.module";

.container {
  position: relative;

  .input {
    padding: 0 0 0 10px;
  }

  * {
    cursor: default;
    height: 100%;
    border: 0;
    background-color: transparent;
    -webkit-user-select: none;
    -ms-user-select: none; 
    user-select: none;
  }
}

.labelContainer {
  width: inherit;
}

.disabled {
  @extend .disabled;

  background-color: var(--input_disabled--background);

  opacity: .5;
  filter: grayscale(.8);
}

.disabledButton {
  svg, svg * {
    cursor: not-allowed;
  }
}

.controls {
  display: flex;
  position: relative;
  flex-direction: row;
  height: 100%;
  padding: 0 5px;

  * {
    cursor: pointer;
  }

  .add, .subtract {
    margin: 0 5px;

    svg {
      cursor: pointer;
    }

    &.disabled {
      opacity: .5;
      filter: grayscale(.8);

      svg, svg * {
        cursor: not-allowed;
      }
    }
  }
}

[dir = "rtl"] {
  .container {
    .input {
      padding: 0 10px 0 0;
    }
  }

  .controls {
    right: auto;

    .add {
      order: 1;
    }

    .subtract {
      order: 0;
    }
  }
}
