@import "../Shared.module";

.container {
  @extend .container;

  input {
    padding: 0 10px;
  }

	input:focus {
		outline: none !important;
		border: var(--input-focus--border);
		box-shadow: 0 0 6px #0003;
		transition: box-shadow 0.3s ease-in-out;
	}

  &:nth-child(2) {
    padding-right: 0;
  }

  &[data-disabled="true"] {
    @extend .disabled;

    input {
      background-color: var(--input-disabled--background);
    }
  }
}