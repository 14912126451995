[dir='rtl'] .input {
  padding: 0 50px 0 0;
}

.container {
  /**
   * This is used to format input containers.
   */
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: stretch;
  border: var(--input--border);
  background-color: var(--input--background);
  border-radius: 4px;
  padding: 0;
  height: 50px;
  width: 100%;
  font-size: 16px;

  .input {
    outline: none;
    height: 100%;
    width: 100%;
    letter-spacing: 2px;
    font-size: 15px;
    padding: 0 10px;
    background-color: transparent;
    border-radius: 5px;

    border: none;

    &:not(:first-child) {
      margin-top: 0;
      padding: 0 0 0 50px;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.disabled {
  /**
   * May be applied to any container in order to enforce
   * disabled styling.
   */
  filter: grayscale(100%) saturate(10%);
  opacity: .5;
  outline: none;
  -moz-user-select: none;
  user-select: none;

  .container {
      background-color: var(--input-disabled--background);
  }

  input {
    cursor: not-allowed;
  }
}

.controls {
  position: relative;
}