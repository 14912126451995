@import "../../../styles/breakpoints";

.component {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px auto 0;
  max-width: 500px;

  @media only screen and (max-width: $breakpoint-small) {
    margin: 30px 0 0;
  }

}

.message {
  margin: 0;
  font-size: 1.1em;
  font-weight: 400;
  text-align: center;
  width: 100%;
}

.icon {
  width: 50px;
  display: flex;
  > svg {
    fill: var(--input-icon--color) !important;
  }
}