@import "../../../styles/breakpoints";
@import "../../../components/inputs/buttons/Shared.module";

[data-scrolling] {
  .navigation {
    background: var(--header__scrolling-navigation--background);
    .menu .logo {
      svg {
        fill: var(--header__scrolling-navigation-logo--background);
      }
    }

    .menu .links {
      a {
        color: var(--header__scrolling-text--color);
      }
    }
  }
}

.navigation {
  display: flex;
  height: 100px;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 20;
  width: 100%;
  transition: all 0.3s ease-out;
  padding: 25px 0;
  background: var(--header-navigation--background);

  // Responsive design
  @media only screen and (max-width: $breakpoint-medium) {
    height: 94px;
    padding: 20px 0;
  }
  @media only screen and (max-width: $breakpoint-small) {
    padding: 20px 5%;
  }

  // Nested components
  .menu {
    display: flex;
    width: 90%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
    font-size: 1em;
    max-width: $max-content-width;

    @media only screen and (max-width: $breakpoint-medium) {
      position: static;
    }

    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
    }

    > * {
      flex-grow: 0;
    }

    .links {
      display: flex;
      position: relative;
      flex-direction: row;
      flex-wrap: nowrap;
      align-self: center;
      transform-origin: top center;
      gap: 1em;
      width: 100%;
      flex-grow: 1;

      @media only screen and (max-width: calc(#{$breakpoint-720p} + 50px)) {
        display: none;
      }

      a {
        color: var(--header-text--color);
        text-decoration: none;
        text-transform: uppercase;
        height: auto;

        &:hover {
          opacity: 0.7;
        }
      }
    }

		.logoContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

    .logo {
      flex-grow: 0;
      height: var(--header-navigation-logo--height);
      width: var(--header-navigation-logo--width);

      // svg wrapper
      > * {
        height: 100%;

        &.tablet {
          display: none;
        }

        @media only screen and (max-width: $breakpoint-medium) {
          &.desktop {
            display: none;
          }
        }

        @media only screen and (max-width: $breakpoint-medium) and (min-width: $breakpoint-small) {
          &.tablet {
            display: unset;
          }
        }

        @media only screen and (min-width: calc(#{$breakpoint-small} + 1px)) {
          &.mobile {
            display: none;
          }
        }
      }

      svg {
        fill: var(--header-navigation-logo--background);
        height: 100%;
      }
    }
  }
}

.icon {
  display: flex;
  height: 1.2em;
  width: 1.2em;
  align-items: center;
  margin: auto;

  > svg {
    height: 100%;
    width: 100%;
  }
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--header-dropdown--color);
  align-self: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 11px 25px;
  border-radius: 30px;
  height: 2.5em;
	background: var(--header-dropdown--background);

  @media only screen and (max-width: $breakpoint-medium) {
    padding: 0;
    position: relative;
    width: 3em;
    height: 3em;
  }
}

[dir="rtl"] .dropdown {
  :global .label {
    margin: 0 5px 0 0;
  }
}

[data-scrolling] {
  .dropdown {
    > div {
      background: var(--header__scrolling-dropdown--background);
      > div {
        color: var(--header__scrolling-dropdown--color);
  
        svg {
          fill: var(--header__scrolling-dropdown--color);
        }
      }
    }
  }
}

.dropdown {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: $breakpoint-medium) {
    position: static;
  }

  > div {
    background: var(--header-dropdown--background);
    > div {
      color: var(--header-dropdown--color);

      svg {
        fill: var(--header-dropdown--color);
      }
    }
  }

  :global .container {
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 10px;
    height: 2.5em;

    @media only screen and (max-width: $breakpoint-medium) {
      padding: 0;
      justify-content: center;
      border-radius: 50%;
      width: 3em;
      height: 3em;
    }
  }

  :global .label {
    margin: 0 0 0 5px;
    text-transform: uppercase;
    @media only screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }

  [dir="rtl"] :global .label {
    margin: 0 5px 0 0;
  }

  :global .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50px;
    border-radius: 30px;
    overflow: hidden;
    max-height: 0;
    transform: translate(-50%);
    left: 50%;
    box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 5%);
    transition: all 0.2s ease-in;
    min-width: 200px;

    @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) and (max-width: $breakpoint-720p) {
      min-width: 120px;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      left: 0;
      top: 100%;
      width: 100%;
      transform: initial;
      border-radius: 0 0 30px 30px;
      max-height: 0;
      box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 5%);
      transition: all 0.2s ease-in;
    }
  }

  :global .listActive {
    padding: 8px;
    transition: all 0.5s ease-in !important;
    max-height: 500px !important;
    @media only screen and (max-width: $breakpoint-medium) {
      padding: 0;
    }
  }

  :global .option {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 0.5px solid rgba(211, 211, 211, 0.8);
    padding: 10px;

    &:last-child {
      border-bottom: none;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      text-decoration: none;
      padding: 10px;
    }
  }
}

.dropdownContainer {
  display: flex;
  align-items: center;
  justify-content: stretch;
  grid-gap: 0 20px;
  gap: 0 20px;
}

.burgerMenu {
  display: none;
  @media only screen and (max-width: calc(#{$breakpoint-720p} + 50px)) {
    margin-right: 0;
    display: flex;
    width: 2.5em;
    :global .container {
      width: 2.5em;
      justify-content: center;
    }
  }

  @media only screen and (max-width: $breakpoint-medium) {
    margin-right: 0;
    display: flex;
    width: 3em;
    :global .container {
      width: 3em;
      justify-content: center;
    }
  }
}