@import "src/styles/breakpoints";

.container {
  .logistics {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-grow: 1;

    .details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-right: 0;
      text-align: center;
      width: auto;
      flex-grow: 1;
    }

    .windowContainer {
      @extend .details;

      min-width: 100px;
    }

    .luggageCountContainer {
      @extend .details;

      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 0 10px;

      .icon {
        position: relative;
        height: 32px;
      }
    }

    @media only screen and (max-width: $breakpoint-large) {
      flex-direction: column;
      gap: 20px;
    }
  }
}

