.input {
  padding-left: 45px;
  padding-right: 10px;
  width: 100%;
  height: 50px;
  font-size: 100%;
  border-radius: 3px;
  border: var(--input--border);
  background-color: var(--input--background);
}

[dir='rtl'] .input {
  padding-left: 10px;
  padding-right: 45px;
}

.list {
  padding: 0;
  margin: {
    top: 10px;
  };
  position: absolute;
  z-index: 99;
  background-color: #fff;
  width: 100%;

  border: var(--input--border);
  border-radius: 3px;

  &::after {
    content: "";
    display: block;
    position: relative;

    width: 100%;
    height: 40px;

    background: url("../../../../../resources/images/powered_by_google.png") calc(95%) center / auto 40% no-repeat;
  }
}

.list-item {
  display: flex;
  align-items: center;
  padding-left: 15px;
  height: 40px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //border-bottom: 1px solid desaturate(#0085bb, 50%);
  background-color: inherit;

  &:nth-child(2n-1) {
    background-color: rgba(0, 0, 0, .1);
  }

  &:hover {
    cursor: pointer;

    filter: #{"brightness(1)"};
  }

  &[data-disabled="true"] {
    filter: #{"grayscale(.5) opacity(.5)"};
    cursor: not-allowed;

    &:hover {
      filter: #{"brightness(.5) grayscale(.5) opacity(.5)"};
    }
  }
}

[dir="rtl"] .list-item {
  padding-left: 0;
  padding-right: 15px;
}

.google-image {
  margin-left: auto;
  height: 35px;
  padding: 10px;
}
