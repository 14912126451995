.option {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 15px;
  cursor: pointer;
  align-items: center;
  border-bottom: 2px solid #f2f2f2;
  background-color: var(--input-dropdown-option--background);

  &:last-child {
    border-bottom: none;
  }
}


.option-value {
  text-transform: uppercase;
  font-weight: 400;
}