@import "../../../styles/breakpoints";

.container {
  background: #F9F9F9;
  border-radius: 10px;
  margin-top: 5px;
  padding: 15px;
  cursor: pointer;
}

.row {
  display: grid;
  grid-template-columns: calc(17% - 5px) calc(16% - 5px) calc(26% - 5px) calc(25% - 5px) calc(5% - 5px) calc(5% - 5px);
  gap: 5px 6px;
  grid-gap: 5px 6px;

  @media only screen and (max-width: $breakpoint-medium) {
    grid-template-columns: 100%;
    text-align: center;
    gap: 5px 5px;
    grid-gap: 5px 5px;
  }
}

.rotate {
  transform: rotate(180deg);
}

.column {
  color: #98A4A8;

  &:first-child {
    font-weight: bold;
  }
}

.arrow-icon {
  width: 15px;
  top: 2.8rem;
  right: 15px;
  pointer-events: none;
  fill: var(--input-icon--color);
}

.centered {
  margin: auto;
}


.nestedGridRow {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px 10px;
  grid-gap: 10px 10px;
  margin-bottom: 5px;
  color: #98A4A8;

  @media only screen and (max-width: $breakpoint-medium) {
    grid-template-columns: 100%;
    gap: 5px 5px;
    grid-gap: 5px 5px;
  }
}

.nestedGridColumn {
  &:first-child {
    font-weight: bold;
  }
}
