@import "../../../styles/breakpoints";

.switch {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (max-width: $breakpoint-small) {
    justify-content: center;
  }
}

.label {
  font-weight: 300;
  font-size: 15px;
}

.toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  height: 24px;
  width: 48px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  margin: 5px;
  border: var(--toggle--border);
  background: #fff;
  transition: all 0.3s ease;

  &:focus {
    outline: 0;
  }

  &:after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: var(--toggle-handle--background);
    box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
    transition: all 0.3s cubic-bezier(0.5, 0.1, 0.75, 1.35);
  }

  &:checked {
    &:after {
      transform: translatex(24px);
    }
  }
}
