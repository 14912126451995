@import "../../../styles/breakpoints";

.input {
  padding-left: 45px;
  padding-right: 10px;
  width: 100%;
  height: 50px;
  font-size: 100%;
  border-radius: 3px;
  border: var(--input--border);
  background-color: var(--input--background);
}

[dir='rtl'] .input {
  padding-left: 10px;
  padding-right: 45px;
}

.container {
  z-index: 1;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 40%;

  @media only screen and (max-width: $breakpoint-large) {
    width: 50%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    width: 60%;
  }
  @media only screen and (max-width: $breakpoint-small) {
    width: calc(100% - (20px));
  }
}

.modalContent {
	overflow: scroll;
	max-height: 500px;
}

.addressButton {
	margin-bottom: 10px;
	width: 100%;
}