@import "src/styles/Shared.module";

.wrapper {
  width: 80%;
  max-width: $max-content-width;
  display: flex;
  position: relative;
  justify-content: center;

  @media only screen and (max-width: $breakpoint-large) {
    width: 90%;

    &:first-child {
      margin-top: 20px;
    }
  }

  .container {
    @extend .component;

    display: block;
    position: relative;
    width: 100%;

    .spinner {
      > :global(.container) {
        background-color: rgba(1, 1, 1, 0.2);
      }
    }
  }
}