.container {
  display: inline-block;
  color: var(--text-secondary-color);

  &:before {
    display: inline-block;
    content: " ";
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltipText {
  visibility: hidden;
  color: #555;
  text-align: left;
  padding: 12px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  opacity: 0;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(112, 112, 112, 0.09);
  border-radius: 20px;
  transition: opacity 0.3s;
  background-color: #fff;
  font-size: small;
  right: -18px;

  > span {
    float: left;
    clear: left;
  }

  > span:not(:last-child) {
    margin-bottom: 5px;
  }
}

.tooltip .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 1pc;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.tooltip:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}

.icon {
  width: 1em;
  cursor: pointer;
}

.superscript {
  color: (--text-secondary-color);
  font-size: 0.7em;
}