@use "sass:math";
@import "../../styles/breakpoints";

$width: 56px;
$offset: -20px;
$lineWidth: 4px;

.step-number {
  position: absolute;
  left: -1 * $width + $offset;

  background: var(--flow-progress-bar-icon__inactive--background);
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);
  border: 4px solid #fff;
  border-radius: 50%;

	top: 50%;
	transform: translateY(-50%);

  min-height: $width;
  height: $width;
  min-width: $width;
  width: $width;

  text-align: center;
  user-select: none;

  z-index: 1;
  @media only screen and (max-width: $breakpoint-large) {
    display: none;
  }

  span {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
  }
}

[dir="rtl"] .step-number {
  left: auto;
  right: -1 * $width + $offset;
}

.active {
	top: 30px;
	transform: initial;
  color: #fff;
  background: var(--flow-progress-bar-icon__active--background);
}

.done {
  background: var(--flow-progress-bar-icon__submitted--background);
  color: #fff;
}

.line-container {
  display: flex;
  height: 100%;
  position: absolute;
  left: $offset + -1 * math.div($width, 2) - math.div($lineWidth, 2);

  @media only screen and (max-width: $breakpoint-large) {
    display: none;
  }
}

[dir="rtl"] .line-container {
  left: auto;
  right: $offset + -1 * math.div($width, 2) - math.div($lineWidth, 2);
}

.line {
  border-left: $lineWidth solid #e4eded;
  height: 100%;
  margin-top: auto;
  margin-bottom: -60px;
}

.line-done {
  border-left: var(--flow-progress-bar-line__submitted--border);
}
