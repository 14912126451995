@import "../../styles/breakpoints";

.wideColumn {
  flex-grow: 10 !important;

  @media only screen and (max-width: $breakpoint-small) {
    width: 100%;
    padding: 0;
  }
}

.narrowColumn {
  @media only screen and (max-width: $breakpoint-small) {
    max-width: 50%;
    padding: 0;
  }
}

.maxWidth {
  max-width: 150px;

  @media only screen and (max-width: $breakpoint-small) {
    max-width: 50%;
    padding: 0;
  }
}

.address {
  display: flex;
  flex-direction: column;
  font-weight: 200;
  font-size: 14px;
  align-self: flex-start;
  justify-content: flex-end;
  text-align: start;

  @media only screen and (max-width: $breakpoint-large) {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: initial;
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: unset;

    > div {
      flex-grow: 1;
    }

    > div:not(:only-child) {
      padding: 0 1em;
      @media only screen and (max-width: $breakpoint-small) {
        padding: 0;
      }

      &:first-child {
        padding: 0 1em 0 0;
        @media only screen and (max-width: $breakpoint-small) {
          padding: 0;
        }
      }

      &:last-child {
        padding: 0 0 0 1em;
      }
    }
  }
}

[dir="rtl"] .address {
  > div {
    > div:not(:only-child) {
      padding: 0 1em;
      @media only screen and (max-width: $breakpoint-small) {
        padding: 0;
      }

      &:first-child {
        padding: 0 0 0 1em;
        @media only screen and (max-width: $breakpoint-small) {
          padding: 0;
        }
      }

      &:last-child {
        padding: 0 1em 0 0;
      }
    }
  }
}

.selected {
  input:disabled {
    color: initial;
    filter: initial;
  }
}

.locationCheckbox {
  margin: 10px 0;
}

.disabledCheckBox {
  opacity: .3;
}

.addressButton {
  margin-bottom: 10px;
}

.addressModal {
  width: fit-content;
  font-size: 22px;
  z-index: 1001;
}

.modalContentWrapper {
  max-width: 100%;
}

.modalContent {
  display: flex;
  flex-direction: column;
}