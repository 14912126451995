.airline-code {
  height: 50px;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 15px;
  flex-grow: 0;
  flex-shrink: 1;
  display: block;
  position: relative;
  max-width: 35px;
  text-align: center;
  background-color: var(--input--background);
  border: var(--input--border);
}

.airline-code:focus {
	outline: none !important;
	border: var(--input-focus--border);
	box-shadow: 0 0 6px #0003;
	transition: box-shadow 0.3s ease-in-out;
}


.airline-code::placeholder {
	opacity: 0.2;
}