.icon {
  width: 26px;
  display: flex;
  flex-direction: row;
  position: relative;
  margin: auto;

  > svg {
    fill: var(--input-icon--color);
  }
}