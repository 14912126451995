@import "../../../../../styles/breakpoints";

.heading {
  font-weight: 400;

  @media only screen and (max-width: $breakpoint-large) {
    width: 50%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    text-align: center;
  }
}

.container {
  h2 {
    @extend .heading;

    margin: 0 0 20px;
  }

  h4 {
    @extend .heading;

    margin-bottom: 20px;
  }

  .formWrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

.heading {
  margin: 0 0 20px;
  font-weight: 400;
  @media only screen and (max-width: $breakpoint-large) {
    width: 50%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    text-align: center;
  }
}

.airlineInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-grow: 2;
  width: 100%;
  @media only screen and (max-width: $breakpoint-900p) {
    margin-top: 20px;
  }
}

.airlineContainer > div:nth-child(2) {
  flex: 1;
}

[dir="rtl"] .airlineContainer > div > div .airlineContainer > div > div > div > div > div {
  justify-content: flex-end;
  direction: ltr;
}

[dir="rtl"] .airlineContainer > div > div > div {
  justify-content: flex-end;
  direction: ltr;
}

.nameContainer {
  position: relative;
  flex-basis: auto;
  margin-top: 5px;
  flex-grow: 1;
  align-items: stretch;
  @media only screen and (max-width: $breakpoint-900p) {
    width: 100%;
  }
  @media only screen and (max-width: $breakpoint-900p) {
    margin-right: 0;
  }
}

.nameInput {
  width: 100%;
  height: 50px;
  border-radius: 3px;
  font-size: 15px;
  letter-spacing: 2px;
  padding: 0 45px !important;
  direction: ltr;
}

.caret {
  width: 18px;
  position: absolute;
  right: 15px;
  top: 0.9rem;
  z-index: 99;
  pointer-events: none;
}

[dir="rtl"] .caret {
  right: auto;
  left: 15px;
}

.chevronIcon {
  width: 17px;
  position: absolute;
  right: 15px;
  top: 1rem;
  z-index: 999;
}

.flightInputContainer {
  display: flex;
  margin-bottom: 5px;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-grow: 2;
  flex-basis: auto;
  flex-shrink: 1;
  max-width: 47%;
  min-width: 240px;
  flex-direction: column;

  @media only screen and (max-width: $breakpoint-medium) {
    align-items: center;
    max-width: 100%;
  }
}

.airlineInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  flex-grow: 2;
  width: 100%;
  @media only screen and (max-width: $breakpoint-900p) {
    margin-top: 20px;
  }
}

.pnrContainer {
  position: relative;
  flex-basis: auto;
  margin-top: 5px;
  flex-grow: 1;
  align-items: stretch;
  min-width: 50%;

  @media only screen and (max-width: $breakpoint-900p) {
    width: 100%;
  }
  @media only screen and (max-width: $breakpoint-900p) {
    margin-right: 0;
  }
}

.icon {
  width: 19px;
  position: absolute;
  top: 0.7rem;
  left: 15px;
  pointer-events: none;

  > svg {
    stroke: var(--input-icon--color) !important;
    fill: transparent !important;
  }

  @media only screen and (max-width: $breakpoint-small) {
    height: 50px;
    display: flex;
    padding: 0 0 20px;
  }
}

.dropdown {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  @media only screen and (max-width: $breakpoint-medium) {
    position: static;
  }

  :global .container {
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 10px;
    height: 2.5em;

    @media only screen and (max-width: $breakpoint-medium) {
      padding: 0;
      justify-content: center;
      border-radius: 50%;
      width: 3em;
      height: 3em;
    }
  }

  :global .label {
    margin: 0 0 0 5px;
    text-transform: uppercase;
    @media only screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }

  [dir="rtl"] :global .label {
    margin: 0 5px 0 0;
  }

  :global .list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50px;
    border-radius: 30px;
    overflow: hidden;
    max-height: 0;
    transform: translate(-50%);
    left: 50%;
    box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 5%);
    transition: all 0.2s ease-in;
    min-width: 200px;

    @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) and (max-width: $breakpoint-large) {
      min-width: 120px;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      left: 0;
      top: 93px;
      width: 100%;
      transform: initial;
      border-radius: 0 0 30px 30px;
      max-height: 0;
      box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 5%);
      transition: all 0.2s ease-in;
    }
  }

  :global .listActive {
    padding: 8px;
    transition: all 0.5s ease-in !important;
    max-height: 500px !important;
    @media only screen and (max-width: $breakpoint-medium) {
      padding: 0;
    }
  }

  :global .option {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 0.5px solid rgba(211, 211, 211, 0.8);
    padding: 10px;

    &:last-child {
      border-bottom: none;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      text-decoration: none;
      padding: 10px;
    }
  }
}
