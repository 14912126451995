@import "src/styles/breakpoints";

$border: 2px solid #e8e8e8;

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  gap: 20px;
  min-height: 100px;

  @media only screen and (max-width: $breakpoint-large) {
    padding: 20px;
    gap: 25px;
    flex-direction: column;
  }

  @media only screen and (max-width: $breakpoint-small) {
    gap: 20px;
  }

  h2 {
    font-size: 22px;
    font-weight: 400;
    flex-grow: 2;
    color: var(--flow-step-completed-heading--color);

    @media only screen and (min-width: $breakpoint-720p) {
      margin: auto 0;
    }

    @media only screen and (max-width: $breakpoint-large) {
      text-align: center;
      width: auto;
    }
  }

  :global(.step-content) {
    display: flex;
    flex-direction: row;
    flex-grow: 3;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    @media only screen and (max-width: $breakpoint-large) {
      flex-direction: column;
    }
  }

  .buttonWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;

    input {
      margin: 0;
    }

    &:before {
      display: block;
      content: "";
      position: relative;
      align-self: stretch;

      border: {
        left: $border;
      }
      margin: 0 10px;
    }

    @media only screen and (max-width: $breakpoint-large) {
      margin: {
        top: 10px
      };
      &:before {
        display: none;
      }
    }
  }
}
