.container {
  position: relative;

  h2 {
    > span {
      color: var(--color-dark);
      font-weight: bold;

      &:before {
        content: " ";
      }
    }
  }
}