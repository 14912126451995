@import "../../../styles/breakpoints";

.gridRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px 10px;

  &:first-child {
    margin-top: 30px;
    font-weight: bold;
  }

  @media only screen and (max-width: $breakpoint-small) {
    grid-template-columns: 100%;
    text-align: center;
    gap: 5px 5px;
    grid-gap: 5px 5px;
  }
}

.nestedGridContainer {
  margin-top: 15px;
}

.nestedGridRow {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px 10px;
  grid-gap: 10px 10px;
  margin-bottom: 5px;
  color: #98A4A8;

  @media only screen and (max-width: $breakpoint-medium) {
    grid-template-columns: 100%;
    gap: 5px 5px;
    grid-gap: 5px 5px;
  }
}

.nestedGridColumn {
  &:first-child {
    font-weight: bold;
  }
}

.rowTitle {
  color: var(--text-secondary-color)
}

.bold {
  font-weight: bold;
}