@import "../../styles/breakpoints";
@import "../../components/inputs/buttons/Shared.module";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100&display=swap');

.header {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: stretch;

  background: var(--header--background);
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: var(--header--height);

  @media only screen and (max-width: $breakpoint-large) {
    height: 20vh;
    min-height: 150px;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    height: 10vh;
    min-height: 94px;
  }
  @media only screen and (max-width: $breakpoint-small) {
    height: 94px;
  }
}

[dir="rtl"] .header {
  background: var(--header-inverted--background);
  background-size: cover;
  background-repeat: no-repeat;
}

.hero {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  background: var(--header-navigation--overlay)
}

.content {
  display: flex;
  position: relative;

  flex-direction: column;
  align-items: center;
  text-align: center;

  width: 80%;
  max-width: $max-content-width;

  @media only screen and (max-width: $breakpoint-large) {
    width: 90%;
  }

  margin: {
    left: auto;
    right: auto;
  }

  .headerSlogan {
		font-family: var(--h1--font), sans-serif;
    font-size: var(--h1--size);
    text-transform: none;
    letter-spacing: 0;
    color: var(--h1--color);

		@media only screen and (min-width: $breakpoint-1440p) {
      font-size: 3.5em;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      font-size: 1.2em;
      padding-top: 5vh;
      text-align: center;
    }

    @media only screen and (max-width: $breakpoint-large) {
      display: none
    }
  }
}

.thankYou {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;

  @media only screen and (max-width: $breakpoint-large) {
    display: none;
  }
}