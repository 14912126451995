@import "../../styles/breakpoints";

.heading {
  margin: 0 0 20px;
  font-weight: 400;
}

.container {
  width: 100%;
  display: flex;
	flex: 1;
  position: relative;
  justify-content: center;
  z-index: 1;

  @media only screen and (max-width: $breakpoint-medium) {
    margin-top: 0;
		flex-direction: column;
  }
}

.content {
  width: 80%;
  margin: 0 auto;
  max-width: $max-content-width;
  margin-bottom: 140px;

  @media only screen and (max-width: $breakpoint-medium) {
    width: 90%;
  }
}

.thankYou {
  display: none;
  margin: auto;

  @media only screen and (max-width: $breakpoint-large) {
    display: block;
    width: 80%;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    width: 90%;
  }
}