@import "../../../../styles/breakpoints";

$maxButtonHeight: 50px;

.button {
  text-transform: uppercase;
  border: none;
  color: #000;
  outline: none;
  cursor: pointer;
  max-height: $maxButtonHeight;
  border-radius: $maxButtonHeight;
  height: 40px;
  margin: 0 0 0 auto;
  padding: 12px 30px;

  @media only screen and (max-width: $breakpoint-medium) {
    margin: 0 auto
  }

  &:disabled {
    background-color: #dcdcdc;
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    filter: saturate(200%) contrast(150%);
		transition: all 0.2s ease-in;
  }
}

[dir="rtl"] .button {
  margin: 0 auto 0 0;

  @media only screen and (max-width: $breakpoint-medium) {
    margin: 0 auto
  }
}

.button-disabled {
  background-color: #dcdcdc;
  margin: auto;
}

.submit {
  background: var(--flow-step-submit-button--background);
  color: var(--flow-step-submit-button--color);
  border-radius: var(--flow-step-submit-button--border-radius);
}

.reset {
  background: var(--flow-step-edit-button--background);
  color: var(--flow-step-edit-button--color);
  border-radius: var(--flow-step-edit-button--border-radius);
  @media only screen and (max-width: $breakpoint-medium) {
    margin-right: 0;
  }
}