@import "../../../../styles/breakpoints";

.heading {
  font-weight: 400;

  @media only screen and (max-width: $breakpoint-large) {
    width: 50%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    text-align: center;
  }
}

.container {
	display: flex;
	justify-content: center;
	flex-direction: column;

	h2 {
    @extend .heading;

    margin: 0 0 20px;
  }

  h4 {
    @extend .heading;

    margin-bottom: 20px;
  }
}

.form {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.label {
	width: 50%;
	@media only screen and (max-width: $breakpoint-medium) {
		width: 100%;
  }
}

.submit {
	margin-top: 20px;
}

.icon {
  position: absolute;
  left: 15px;
  pointer-events: none;
	top: 50%;
	height: 25px;
	transform: translateY(-50%);

  > svg {
		height: 25px;
    fill: var(--input-icon--color) !important;
  }
}