.active {
  background: var(--color-secondary);
  color: var(--color-text-light)
}

.accordionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 1em;
}

.accordionTitle:hover {
  background: var(--color-secondary);
  color: var(--color-text-light);
}

.accordionContent {
  padding: 1em 1em 2em;
}
