@import "../../../../../styles/breakpoints";

.disabledContainer {
	cursor: not-allowed;
	opacity: 0.5;
	pointer-events: none;
}

.nameInputContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;

  div {
    flex-basis: 47%;
  }
}

.ltr {
  direction: ltr;
}

.passwordContainer {
  margin-top: 10px;
}

.disabled {
  cursor: not-allowed;
}

.disabled input {
  pointer-events: none;
}

.loginContainer {
  display: flex;
  flex-direction: column;
}