.icon {
  width: 19px;
  position: absolute;
  top: 0.7rem;
  left: 15px;
  pointer-events: none;

  > svg {
    fill: var(--input-icon--color);
  }
}
