@import "../../styles/breakpoints";

.portal {
  min-height: 1000px;
  width: 80%;
  max-width: $max-content-width;
  position: relative;
  margin: 0 auto;
	background: var(--page--background);

  @media only screen and (max-width: $breakpoint-large) {
    width: 90%;

    &:first-child {
      margin-top: 20px;
    }
  }
}

.container {
  display: grid;
  grid-template-columns: calc(35% - 15px) calc(65% - 15px);
  gap: 30px 30px;
  grid-gap: 30px 30px;
  align-items: flex-start;

  @media only screen and (max-width: $breakpoint-medium) {
    grid-template-columns: 100%;
    gap: 0;
    grid-gap: 0;
  }
}