.message {
  font-size: 14px;
  color: var(--flow-error--color);
  margin-top: 5px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  //todo
}

.pointer {
	cursor: pointer;
}