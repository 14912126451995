.component {
  position: relative;
}

.row {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20px auto;
  width: 90%;
}

.column {
  text-align: center;
}

.regulationIcon {
  height: 100%;
  width: 100%;
  margin: 5px;
  > svg {
    fill: var(--color-dark) !important;
  }
}

.item {
  margin: 5px 20px;
  text-align: center;
}

.itemName {
  margin: 5px auto 0;
  font-size: 1.1em;
  color: var(--color-dark);
}

.itemHeader {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.sectionName {
  font-size: 1.1em;
  text-align: center;
  margin: 10px auto;
  color: var(--color-dark);
}

.sectionBody {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}