@import "../../styles/breakpoints";

.page {
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  width: auto;
  text-align: center;

  @media only screen and (min-width: $breakpoint-large) {
    margin-top: 150px;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    margin-top: 25px;
  }
}

.text {
  color: var(--text-secondary-color);
  font-size: 1.5em;
}

.button {
  margin: 20px auto 0;
}

[dir="rtl"] .button {
  margin: 20px auto 0;
}