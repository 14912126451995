@import "../../../styles/breakpoints";

.accordion {
  margin: 0 auto 2em;
}

.accordion:last-child {
  margin: 0 auto;
}

.title {
  margin: 0 0 1em;
  font-size: 1.2em;
}