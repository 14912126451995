@import "../../../../styles/breakpoints";

.heading {
  margin: 0 0 20px;
  font-weight: 400;
  @media only screen and (max-width: $breakpoint-large) {
    width: 50%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    text-align: center;
  }
}

.radio-input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.radio-input {
  display: flex;
  margin-right: 30px;
}

.radio-input-text {
  margin-left: 5px;
  font-size: 15px;
}

input[type="radio"] + label:before {
  border: 2px solid #0085bb;
}

input[type="radio"]:checked + label:before {
  background-color: #f83700;
  padding: 2px;
  border: 2px solid #0085bb;
}

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 10px 20px;
}

.stopover-container {
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: $breakpoint-medium) {
    margin: 10px 0;
    text-align: center;
    max-width: 100%;
  }
}

.switch-container {
  margin-top: 20px;
}

.switch-link {
  display: inline-block;
  font-size: 15px;
  color: #f83600;
  text-decoration: underline;
  cursor: pointer;
}


[dir="rtl"] .icon {
  left: auto;
  right: 15px;
}

.dateIcon {
  width: 22px;
  position: absolute;
  left: 15px;
  top: 0.8rem;
  z-index: 999;
}


.description-container {
  flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 0;
  max-width: 50%;
  padding: 10px;

  @media only screen and (min-width: $breakpoint-900p) {
    max-width: 400px;
  }
  @media only screen and (max-width: $breakpoint-small) {
    display: none;
  }
}

.caret {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 15px;
  flex-grow: 0;
  right: 15px;
  text-align: center;
  pointer-events: none;

  svg {
    display: block;
    width: 100%;
  }
}

[dir="rtl"] .caret {
  right: auto;
  left: 15px;
}

.journeyList {
  display: flex;
  flex-direction: column;
}

.journeyButton {
  margin: 0;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  &:first-child {
    margin-top: 10px;
  }
}

.recaptchaContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;

	.recaptchaText {
		margin-bottom: 10px;
	}
}