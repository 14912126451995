@import "../../StepComponent";
@import "../../../../../styles/breakpoints";

$table-spacing: 1em;

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  table {
    display: table;
    position: relative;

    width: 100%;

    font-size: 1em;
    text-align: center;

    border-spacing: $table-spacing;

    label {
      display: block;
      font-weight: bold;
      margin-bottom: $table-spacing;
    }

    thead th {
      vertical-align: top;
      padding-bottom: $table-spacing;
    }

    tbody {
      position: relative;
    }

    tbody tr:first-of-type td[data-field="SharedAllowance"] {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% / 5 - $table-spacing);
    }

    tbody :not(tr:first-of-type) td[data-field="SharedAllowance"] {
      opacity: 0;
    }

    td[data-field="SharedAllowance"] {
      width: calc(100% / 5);
    }

    td[data-field="leftLine"],
    td[data-field="rightLine"] {
      position: relative;
      min-width: 40px;

      div {
        position: absolute;
        height: 2px;
        width: calc(100% - 2px);
        top: calc(50% + 2px);
        background: var(--input-icon--color);
      }

      &.extended {
        @media only screen and (max-width: $breakpoint-medium) {
          display: none;
        }
      }
    }

    td[data-field="SharedAllowance"] {
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 2px;
        width: 10%;
        background-color: var(--input-icon--color);
        @media only screen and (max-width: $breakpoint-medium) {
          display: none;
        }
      }

      &::before {
        left: -18px;
      }

      &::after {
        right: -12px;
      }
    }

    td[data-field="leftLine"].extended::after,
    td[data-field="rightLine"].extended::after {
      content: "";
      position: absolute;
      top: -20px;
      bottom: 0;
      width: 2px;
      background-color: var(--input-icon--color);
      @media only screen and (max-width: $breakpoint-medium) {
        display: none;
      }
    }

    td[data-field="leftLine"].extended::after {
      right: 0;
    }

    td[data-field="rightLine"].extended::after {
      left: 0;
    }

    .halfBorderTop,
    .halfBorderBottom {
      @media only screen and (max-width: $breakpoint-medium) {
        display: none;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        @media only screen and (max-width: $breakpoint-medium) {
          display: none;
        }
      }
    }

    .halfBorderTop::before {
      top: calc(50% + 2px);
    }

    .halfBorderBottom::before {
      top: calc(-50% + 4px);
    }

    .borderLeft::before {
      border-right: 2px solid var(--input-icon--color);
    }

    .borderRight::before {
      border-left: 2px solid var(--input-icon--color);
    }

    .disabled {
      filter: grayscale(100%) saturate(10%);
      opacity: .2;
      outline: none;
      -moz-user-select: none;
      user-select: none;
    }


    td[data-field="Passport"] {
      width: calc(100% / 5);

      > .labelContainer {
        width: 100%;

        div[data-error="true"] {
          display: block;
          position: absolute;
          margin-top: 0;
        }
      }
    }

    td[data-field="LuggageCount"] {
      width: calc(100% / 5);
    }
  }
}

@media only screen and (max-width: $breakpoint-medium) {
  .container {
    table {
      border: 0;
      border-spacing: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tbody {
        display: flex;
        flex-direction: column;
        gap: 2em;

        tr {
          display: flex;
          flex-direction: column;
          gap: 1em;

          &::before {
            display: block;
            content: attr(data-label);
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        td {
          border-bottom: 1px solid #ddd;
          display: block;
          font-size: .8em;
          text-align: right;
          width: 100%;

          &::before {
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        td[data-field] {
          width: 100%;
        }

        td[data-field="FirstName"], td[data-field="LastName"] {
          display: none;
        }

        td[data-field="SharedAllowance"] {
          label {
            float: left;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }

        tr:first-of-type td[data-field="SharedAllowance"] {
          display: unset;
          position: relative;
          top: unset;
          bottom: unset;
          width: 100%;
        }

        :not(tr:first-of-type) td[data-field="SharedAllowance"] {
          opacity: unset;
        }
      }
    }
  }
}