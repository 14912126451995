@import "../../../../../styles/breakpoints";

.container {
  display: inline-block;
  position: relative;
}

.heading {
  margin: 30px 0 20px 0;
  font-weight: 400;
}

.flight-input-codes-wrapper {
  display: flex;
  grid-gap: 0 5px;
  gap: 0 5px;
  direction: ltr;
}

.flight-number-container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  margin: 5px 15px 0 0;
  @media only screen and (max-width: $breakpoint-900p) {
    margin: 5px 0;
  }
}

[dir="rtl"] .flight-number-container {
  margin: 5px 0 0 15px;
  align-items: flex-start;

  @media only screen and (max-width: $breakpoint-900p) {
    margin: 5px 0;
  }
}