@import "src/styles/breakpoints";
@import "src/styles/Shared.module";

.wrapper {
  max-width: $max-content-width;

  > div {
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .container {
    font-size: 15px;
    font-weight: 300;
    color: var(--flow-service-selector__inactive-text--color);
    flex-basis: 100%;
    width: 80%;

    .tabs {
      @include flex;
      position: relative;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-items: stretch;
      gap: 20px;

      @media only screen and (max-width: $breakpoint-medium) {
        max-width: 100%;
        width: 100%;
        margin-right: 0;
        justify-content: space-between;
        flex-direction: row;
      }

      .content {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: flex-start;

        .title {
          font-size: 1.5em;
        }

        @media only screen and (max-width: $breakpoint-small) {
          flex-direction: column;
          text-align: center;
        }
      }
    }
  }

  .button {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);
    border-radius: var(--flow-service-selector--border-radius);
    background-color: var(--flow-service-selector__active--background);
    color: inherit;
    border: none;
    padding: 20px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    flex-basis: 50%;
    max-width: 33%;

    @media only screen and (max-width: $breakpoint-900p) {
      max-width: 50%;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      width: 100%;
      text-align: center;
      justify-content: space-around;
    }

    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
    }
  }
}

.selected {
  > div.content {
    > div.title {
      color: var(--flow-service-selector__active-text--color);
    }

    > div.iconContainer {
      > div {
        opacity: 1;

        > svg {
          fill: var(--flow-service-selector__active-icon--background) !important;
        }
      }
    }

    > div.textContainer {
      opacity: 1;
      color: var(--flow-service-selector__active-text--color);

      > div:first-child {
        color: var(--flow-service-selector__active-text--color);
      }
    }
  }

  > div.checkboxContainer {
    border-bottom: none;
  }

  > div.checkboxIcon {
    opacity: 1;
    border: 1px solid var(--flow-service-selector__active-icon--background);
    border-radius: 50px;

    > svg {
      fill: var(--flow-service-selector__active-icon--background) !important;
    }
  }
}

.iconContainer {
  display: flex;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
}

.icon {
  width: 4em;
  display: flex;
  padding: 0 20px 0 0;
  opacity: 0.7;

  > svg {
    fill: var(--flow-service-selector__inactive-icon--background) !important;
  }

  @media only screen and (max-width: $breakpoint-small) {
    height: 50px;
    display: flex;
    padding: 0 0 20px;
  }
}

[dir="rtl"] .icon {
  padding: 0 0 0 10px;

  @media only screen and (max-width: $breakpoint-small) {
    height: 50px;
    display: flex;
    padding: 0 0 20px;
  }
}

.textContainer {
  text-align: left;
  opacity: 0.7;
  color: var(--flow-service-selector__inactive-text--color);
  @media only screen and (max-width: $breakpoint-small) {
    text-align: center;
  }
}

[dir="rtl"] .textContainer {
  text-align: right;

  @media only screen and (max-width: $breakpoint-small) {
    text-align: center;
  }
}

.checkboxContainer {
  text-align: right;
  position: relative;
  top: -10px;
  right: calc(-100% + 10px);
  left: auto;
  margin-right: -20px;
  margin-bottom: -20px;
  height: 20px;
  width: 20px;
}

[dir="rtl"] .checkboxContainer {
  left: calc(-100% - 10px);
  right: auto;
}

.checkboxIcon {
  width: 20px;
  height: 20px;
  opacity: 0.7;
  border: 1px solid var(--flow-service-selector__inactive--border);
  border-radius: 50px;

  > svg {
    fill: var(--flow-service-selector__inactive-icon--background) !important;
  }
}

[dir="rtl"] .checkbox {
  flex-direction: row;
}