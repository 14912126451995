@import "../../../../../../styles/breakpoints";

.container {
  width: 50%;

  @media only screen and (max-width: $breakpoint-medium) {
    max-width: 100%;
    width: 100%;
    margin-right: 0;
  }
}

.codeField {
  padding: 0;
}

.code {
  width: 100%;
  height: 50px;
  letter-spacing: 2px;
}

.ltr {
  direction: ltr;
}