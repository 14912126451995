.container {
  padding: 6px;
  font-size: 13px;
}

.name {
  background: var(--map-info-window-name--background);
  font-size: 14px;
  font-weight: bold;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.address {
  margin-bottom: 10px;
}

.description {
  color: var(--map-info-window-description--color);
}