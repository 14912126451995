@import "../../../../../styles/breakpoints";
@import "../../../../../styles/Shared.module";

.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;

  @media only screen and (max-width: $breakpoint-medium) {
    flex-direction: column-reverse;
    align-items: center;
  }

  .info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    gap: 10px;

    max-width: 400px;
    width: 50%;
    height: 100%;
    background: var(--color-background);
    padding: 20px;
    border-radius: 20px;
    margin: 20px 0 0;

    font-size: 1em;

    @media only screen and (max-width: $breakpoint-medium) {
      width: 90%;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      width: 100%;
      justify-content: center;
      text-align: center;
    }

    header {
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      @media only screen and (max-width: $breakpoint-medium) {
        flex-direction: column;
      }

      > :first-child {
        display: flex;
        flex-direction: row;
        width: 26px;

        @media only screen and (max-width: $breakpoint-medium) {
            margin: -32px 0 0
        }

        svg {
          height: auto;
          fill: var(--input-icon-color);
        }
      }

      h2 {
        display: inline-block;
        height: fit-content;
        padding: 0;
        margin: 0;

        font-size: 1.2em;
        font-weight: 400;

        color: var(--color-secondary);
      }
    }
  }

  .dateInput {
    width: 100%;
  }
}

.innerContainer {
  display: flex;
  margin-bottom: 5px;
  flex-flow: row wrap;
  align-content: stretch;
  justify-content: stretch;
  flex-grow: 2;
  flex-basis: auto;
  flex-shrink: 1;
  max-width: 47%;

  @media only screen and (max-width: $breakpoint-900p) {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    align-items: center;
    max-width: 90%;
    min-width: 90%;
  }

  @media only screen and (max-width: $breakpoint-small) {
    align-items: center;
    max-width: 100%;
    min-width: 100%;
  }
}

.flightNumberContainer {
  display: flex;
  width: 100%;
  flex-direction: row;

  @media only screen and (max-width: $breakpoint-900p) {
    flex-direction: column;
  }


  @media only screen and (max-width: $breakpoint-medium) {
    align-items: center;
  }
}

.heading {
  margin: 0 0 20px;
  font-weight: 400;

  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    text-align: center;
  }
}

