@import "../../../../../styles/breakpoints";


.languageOption {
  display: flex;
  flex-direction: row;
  padding: 15px;
  cursor: pointer;
  align-items: center;
  border-bottom: 0.5px solid rgba(211, 211, 211, 0.6);
  @media only screen and (max-width: $breakpoint-small) {
    border-bottom: 0.5px solid rgba(255, 255, 255, .2);
  }

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    border-bottom: none;
  }

	.selected {
		border-bottom: 1px solid #fff; 
	}

}

.languageCode {
  font-weight: 400;
  margin-right: 12px;
  text-transform: uppercase;
  opacity: 0.5;
  @media only screen and (max-width: $breakpoint-small) {
  }
}

[dir="rtl"] .languageCode {
	margin-right: 0;
	margin-left: 12px;
}

.languageName {
  text-transform: uppercase;
  font-weight: 400;
}