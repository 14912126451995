.container {
  position: relative;
}

[dir="rtl"] .input {
	direction: rtl;

	> input {
		direction: rtl;
	}

	> div {
		margin-right: 0;
		gap: 10px;
	}

	.PhoneInputCountrySelectArrow {
		margin-right: 10px;
	}
}

.input {
  position: relative;
  flex: 1;
  border-radius: 4px;
  background-color: var(--input--background);

  > input {
    border: none;
    outline: none;
    height: 100%;
    letter-spacing: 2px;
    border-radius: 4px;
    font-size: 15px;
    overflow: hidden;
    width: 100%;
    background-color: var(--input--background);
  }

  > div {
    background-color: var(--input--background);
    padding: 0 15px;
    border-radius: 4px;
  }
}

.Shared_container__18HTI {
  font-size: 22px;
  margin-right: 5px;
}
