@import "../../../../styles/breakpoints.scss";

.location-container {
  position: relative;
}

.left-side-inputs {
  width: 100%;
}

.radio-input-container {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.radio-input {
  display: flex;
  margin-right: 30px;
}

.radio-input-text {
  margin-left: 5px;
  font-size: 15px;
}

.flex {
	display: flex;
	position: relative;
}

.heading {
  margin: 0 0 20px;
  font-weight: 400;

  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    text-align: center;
  }
}

.timeslotInfo {
	position: absolute;
	top: -15px;
	right: -15px;
	z-index: 10;
}

[dir="rtl"] .timeslotInfo {
	right: initial;
	left: -15px;
}

.map-container {
  position: relative;
  margin-left: -30px;
  margin-right: -30px;
  @media only screen and (max-width: $breakpoint-medium) {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.pickup-switch-text {
  margin-bottom: 10px;
}

.chevron-icon {
  width: 22px;
  position: absolute;
  right: 15px;
  top: 0.9rem;
  pointer-events: none;
}

[dir="rtl"] .chevron-icon {
  right: auto;
  left: 15px;
}

.location-icon {
  width: 22px;
  height: 28px;
  position: absolute;
  left: 15px;
  top: 0.7rem;
  pointer-events: none;
}

[dir="rtl"] .location-icon {
  left: auto;
  right: 15px;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  gap: 30px;

  @media only screen and (max-width: $breakpoint-large) {
    flex-direction: column;
    gap: 10px;
  }
}

.addressSelector {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin container {
  display: flex;
  flex-wrap: wrap;
  width: 47%;
  gap: 5px;

  @media only screen and (max-width: $breakpoint-large) {
    width: 100%;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    justify-content: space-around;
    margin-bottom: 0;
  }
}

.flexContainer {
  @include container;
  justify-content: space-between;
}

.addressContainer {
  @include container;
  justify-content: flex-start;
}

.date-time-container {
  font-size: 15px;
  font-weight: 300;
  flex-basis: 47%;
  color: var(--color-secondary);
  @media only screen and (max-width: $breakpoint-large) {
    flex-basis: 100%;
    &:nth-child(2) {
      margin-top: 15px;
    }
  }
}

.dateInput {
  box-sizing: border-box;
  height: 50px;
  border-radius: 3px;
  padding: 10px 40px 10px 40px !important;
  width: 100%;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 2px;
  text-align: left;
}

[dir="rtl"] .dateInput {
  text-align: right;
}

.date-icon {
  position: absolute;
  width: 22px;
  top: 0.8rem;
  left: 10px;
  pointer-events: none;
  z-index: 1;

  > svg {
    fill: var(--input-icon-color);
  }
}

[dir="rtl"] .date-icon {
  left: auto;
  right: 15px;
}

.date-chevron-icon {
  position: absolute;
  width: 15px;
  right: 10px;
  pointer-events: none;

  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}

[dir="rtl"] .date-chevron-icon {
  right: auto;
  left: 10px;
}


.time-slots {
  width: 100%;
  height: 50px;
  border: 0.5px solid var(--color-secondary);
  border-radius: 3px;
  padding: 10px 50px 10px 50px;
  font-size: 15px;
  letter-spacing: 2px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.caret {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 15px;
  flex-grow: 0;
  right: 15px;
  text-align: center;
  pointer-events: none;

  > svg {
    display: block;
    width: 100%;
    fill: var(--input-icon-color) !important;
  }
}

[dir="rtl"] .caret {
  right: auto;
  left: 15px;
}

.time-slot-chevron-icon {
  position: absolute;
  width: 15px;
  right: 10px;
  top: 1rem;
  pointer-events: none;
}

[dir="rtl"] .time-slot-chevron-icon {
  right: auto;
  left: 10px;
}

.date-time {
  width: 100%;
  margin-top: 5px;
  position: relative;
}

.luggage-count {
  position: relative;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  margin-bottom: 20px;
}

.luggage-count-input-container {
  position: relative;
  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.link {
	cursor:pointer;
	color:blue;
	text-decoration:underline;
}

.icon {
  width: 24px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 0.6rem;

  > svg {
    fill: var(--input-icon-color);
  }

  &:last-child {
    right: 45px;
  }

  @media only screen and (max-width: $breakpoint-large) {
    width: 28px;
    right: 15px;
    &:last-child {
      right: 60px;
    }
  }
  transition: all 0.1s ease-in;
}

[dir="rtl"] .icon {
  right: auto;
  left: 10px;

  &:last-child {
    right: auto;
    left: 45px;
  }
}

.submit-button-wrapper {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
}

.checkbox-container {
  cursor: pointer;
  display: flex;
  margin-top: auto;

  input[type="checkbox"] {
    @media only screen and (max-width: $breakpoint-medium) {
      margin-top: 5px;
    }
  }

  label {
    margin-top: 0;
    margin-left: 10px;
    font-size: 14px;
    color: #000;
    font-weight: 150;
  }

  @media only screen and (max-width: $breakpoint-large) {
    width: 80%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    margin-top: 25px;
  }
}

.flex-mobile {
  @media only screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
  }
}

.complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    padding-right: 0;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    width: auto;
    padding: 10px 0;
  }
}

.complete-text {
  margin-bottom: 5px;
  font-weight: bold;
  @media only screen and (max-width: $breakpoint-medium) {
    text-align: center;
  }
}

.complete-colored {
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 5px;
  @media only screen and (max-width: $breakpoint-medium) {
    text-align: center;
  }
}

.complete-location-icon {
  width: 10px;
  opacity: 0.7;
  margin-right: 5px;
  @media only screen and (max-width: $breakpoint-medium) {
    align-self: center;
  }
}

.complete-faded {
  opacity: 0.7;
  font-size: 14px;
  font-weight: 100;
  @media only screen and (max-width: $breakpoint-medium) {
    text-align: center;
  }
}

.luggage-count-button-disabled {
  opacity: 0.3;
}

.disabled {
  transition: 0.1s ease-in-out;
  opacity: 0.5;
  filter: grayscale(.3);
  pointer-events: none;
  cursor: not-allowed;
}

.errorMessage {
  background: #d91a1a;
  border-radius: 20px;
  padding: 20px;
  color: var(--color-footer-text);
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -40%);
  > div {
    color: var(--color-footer-text);
  }
}

.pickupError {
  max-width: none;
  flex: 0 0 auto;
  width: 100%;
}