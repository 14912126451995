@import "../../styles/breakpoints.scss";

.info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);
  max-width: 400px;
  width: 50%;
  height: 100%;
  background: var(--info-card--background);
  color: var(--info-card--color);
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
	position: relative;

  font-size: 1em;

  @media only screen and (max-width: $breakpoint-medium) {
    width: 90%;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    justify-content: center;
    text-align: center;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    @media only screen and (max-width: $breakpoint-medium) {
      flex-direction: column;
    }

    > :first-child {
      display: flex;
      flex-direction: row;

      svg {
				height: 30px;
				width: 30px;
        fill: var(--info-card-icon--fill);
      }
    }

		.icon {
			position: absolute;
			top: 10px;
			right: 10px;
		}

    h2 {
      display: inline-block;
      height: fit-content;
      padding: 0;
      margin: 0;

      font-size: 1.2em;
      font-weight: 400;

      color: var(--info-card-heading--color);
    }

		.subtitle {
			padding: 0;
			margin-top: 10px;
			font-size: 1em;
		}
  }
}

.info.collapsed {
  height: 50px;
  width: 50px;
}

.info.expanded {
  height: auto;
  width: 50%;
  padding: 20px;
}

.error {
  color: var(--info-card-error-color);
  background-color: var(--info-card-error--background);
  header {
    h2 {
      color: var(--info-card-error-heading--color);
    }

    > :first-child {
      svg {
        fill: var(--info-card-error-icon--fill)
      }
    }
  }
}