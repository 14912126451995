@import "../../styles/breakpoints";

.footer {
  height: 400px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--footer-text--color);
  background: var(--footer--background);
  background-size: contain;

  @media only screen and (max-width: $breakpoint-medium) {
    height: auto;
  }


  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background: var(--footer-overlay--background);
  }


  .container {
    display: grid;
    grid-template-columns: 1fr 200px 250px;
    grid-gap: 0 20px;
    gap: 0 20px;
    width: 80%;
    max-width: $max-content-width;
    height: 70%;
    z-index: 1;

    @media only screen and (max-width: $breakpoint-medium) {
      grid-template-columns: 100%;
      padding: 5% 0;
    }

    .copyright {
      opacity: .5;
    }
  }
}

@mixin column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media only screen and (max-width: $breakpoint-medium) {
    text-align: start;
    margin-top: 30px;
  }
}

.right {
  @include column;
  text-align: end;
	font-size: 1em;
}

.left {
  @include column;
  text-align: start;
}

.slogan {
  font-size: 1.4em;
  font-weight: 500;
	margin-bottom: 15px;
}

.poweredByContainer {
  display: flex;
  align-items: center;
  grid-gap: 0 5px;
  gap: 0 5px;

  .poweredByLogo {
    svg {
      fill: var(--footer-logo--color);
      width: 130px;
    }
  }
}

.heading {
  font-weight: bold;
  text-align: end;

  @media only screen and (max-width: $breakpoint-medium) {
    text-align: start;
  }
}

.wrapper {
	div {
		margin-top: 3px;
	}
}

[dir="rtl"] .ltr {
  direction: ltr;
  text-align: start;
  @media only screen and (max-width: $breakpoint-medium) {
    text-align: end;
  }
}

.links {
  text-align: right;

  @media only screen and (max-width: $breakpoint-medium) {
    text-align: left;
  }

  > button, div {
    color: var(--footer-text--color);
    display: inline-block;
    position: relative;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
		margin-top: 3px;
  }

  > a {
    color: var(--footer-text--color);
    display: block;
		margin-top: 3px;
  }
}

[dir='rtl'] .links {
  text-align: left;

  @media only screen and (max-width: $breakpoint-medium) {
    text-align: right;
  }
}
