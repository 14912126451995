@import "src/styles/breakpoints";

.heading {
  margin: 0 0 20px;
  font-weight: 400;

  @media only screen and (max-width: $breakpoint-small) {
    width: 100%;
    text-align: center;
  }
}

.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  > div {
    flex-grow: 1;
    width: 50%;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }

  .selector {
    width: 100%;
  }

  .container {
    width: 47%;

    @media only screen and (max-width: $breakpoint-medium) {
      width: 100%;
      margin-bottom: 20px;
    }
    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
    }
  }

  .disabledForm {
    opacity: 0.5;
    cursor: not-allowed;
    input, div {
      pointer-events: none;
    }
  }

  .checkbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 10px;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      pointer-events: none;
      box-sizing: border-box;
    }

    input[type="checkbox"]:checked + label::before {
      opacity: 1;
    }

    input[type="checkbox"]:checked + label::after {
      border: 1px solid #06f;
      background-color: #06f;
    }

    label::before {
      border: 1px solid transparent;
      border-right: 2px #fff;
      border-bottom: 2px #fff;
      border-radius: 0 2px 1px 2px;
      content: "";
      height: 11px;
      left: 1px;
      opacity: 0;
      position: absolute;
      transform: rotate(37deg);
      transform-origin: 100% 100%;
      transition: opacity 0.2s ease-out;
      width: 6px;
      z-index: 1;
      @media only screen and (max-width: $breakpoint-small) {
        left: 2.5px;
      }
    }

    label {
      position: relative;
      padding-left: 24px;
      cursor: pointer;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 200;
      -webkit-user-select: none;
      user-select: none;
      @media only screen and (max-width: $breakpoint-small) {
        padding-left: 30px;
      }
    }

    label:focus {
      &::after {
        border: 1px solid #06f;
        box-shadow: 0 0 0 2px #99c2ff;
      }
    }

    label:hover {
      &::after {
        box-shadow: 0 0 1px 1px #b9c4c9;
      }
    }

    label::after {
      content: "";
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      background-color: #fff;
      border: 1px solid #b9c4c9;
      z-index: 0;
      transition: all 0.15s ease-out;
      @media only screen and (max-width: $breakpoint-small) {
        width: 20px;
        height: 20px;
      }
    }

    @media only screen and (max-width: $breakpoint-small) {
      width: 100%;
      margin-top: 25px;
    }
  }

  [dir="rtl"] .checkbox {
    flex-direction: row;
  }
}

.headingContainer {
  display: flex;
  flex-direction: column;
}

.error {
  // TODO make this nicer
  margin-top: -57px;
  width: 100%;
  padding: 1em;
  background: var(--flow-payment-error--background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--flow-payment-error--color);
  border: var(--flow-payment-error--border);
  border-radius: var(--flow-payment-error--border-radius);

  @media only screen and (max-width: $breakpoint-medium) {
    margin-top: 20px;
  }
}

.paymentError {
  display: flex;
  align-items: center;

  > svg {
    height: 22px;
    width: 22px;
  }
}

p {
  font-weight: 200;
  font-size: 13px;
  margin-top: 20px;
}

.submitContact {
  margin-left: auto;
  margin-top: 15px;
}

.paymentLinkSuccessful {
	border: 3px solid #0ABF53;
	border-radius: 4px;
	height: 427px;
	gap: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.textWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px;
		width: 100%;
		span {
			margin-left: 20px;
		}

		.successIcon {
			svg {
				width: 60px;
				height: 60px;
			}
		}
	}

	.buttonWrapper {
		display: flex;
		gap: 30px;
	}
}

[dir="rtl"] .paymentLinkSuccessful span {
	margin-right: 20px;
}

.confirmation {
	padding: 20px 0;
	display: flex;
	justify-content: center;

	.cancelButton {
		margin: 0;
		margin-right: 10px;
	}
	.confirmButton {
		margin: 0;
		margin-left: 10px;
	}
}