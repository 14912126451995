@import "../../inputs/Shared.module";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: relative;

  .icon {
    margin: 0 10px;

    > svg {
      width: 26px;
      height: 26px;
      margin: auto;
    }
  }

  .caret {
    margin: 0 10px;

    > svg {
      margin: auto;
      width: 15px;
      height: 15px;
    }
  }

  :not(:first-child) {
    flex-grow: 1;
  }
}

.label {
  display: block;
  position: relative;

  color: var(--input-label--color);
  font-size: 15px;
  font-weight: 300;
  margin: 5px 0;
}

.wrapper {
  display: block;
  position: relative;

  .labelContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

