@import "../../../../../../styles//breakpoints.scss";

.wideColumn {
  flex: 2;
	
  @media only screen and (max-width: $breakpoint-small) {
    width: 100%;
    padding: 0;
  }
}

.narrowColumn {
  flex: 1;

  @media only screen and (max-width: $breakpoint-small) {
    max-width: 50%;
    padding: 0;
  }
}

.address {
  display: flex;
  flex-direction: column;
  font-weight: 200;
  font-size: 14px;
  align-self: flex-start;
  justify-content: flex-end;
  text-align: start;

  @media only screen and (max-width: $breakpoint-large) {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: initial;
    width: 100%;
  }

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: unset;
		gap: 1em;
  }
}

.selected {
  input:disabled {
    color: initial;
    filter: initial;
  }
}

.locationCheckbox {
  margin: 10px 0;
}

.disabledCheckBox {
  opacity: .3;
}

.addressButton {
  margin-bottom: 10px;
}
