@import "../../../inputs/Shared.module";
@import "../../../../styles/breakpoints";

.container {
  position: relative;
  cursor: pointer;

  input {
    cursor: pointer;
  }
}

.disabled {
  @extend .disabled;

  pointer-events: none;

  .container {
    cursor: not-allowed;
  }
}

.dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: $breakpoint-medium) {
    position: static;
  }

  :global .container {
    display: flex;
    align-items: center;
    border-radius: 30px;
    padding: 10px;
    width: 100%;
    height: 100%;
  }

  :global .label {
    text-transform: uppercase;
    font-size: 15px;
    color: initial;
    font-weight: initial;
    @media only screen and (max-width: $breakpoint-medium) {
      margin: 0;
    }
  }

  :global .list {
    display: flex;
    visibility: hidden;
    flex-direction: column;
    position: absolute;
    top: 55px;
    width: 100%;
    overflow: hidden;
    max-height: 0;
    transform: translate(-50%);
    left: 50%;
    border-radius: 4px;
    box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 5%);
    z-index: 1;
    @media only screen and (max-width: $breakpoint-medium) {
      left: 0;
      width: 100%;
      transform: initial;
      max-height: 0;
      box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 5%);
    }
  }

  :global .listActive {
    visibility: visible;
    overflow: auto;
    transition: all 0.5s ease-in !important;
    max-height: 180px;
    border: var(--input-dropdown-list--border);
    background-color: var(--input--background);
    @media only screen and (max-width: $breakpoint-medium) {
      padding: 0;
    }
  }

  :global .option {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    text-transform: uppercase;
    border-bottom: 0.5px solid rgba(211, 211, 211, 0.8);
    padding: 16px 20px;

    &:last-child {
      border-bottom: none;
    }

    @media only screen and (max-width: $breakpoint-medium) {
      text-decoration: none;
      padding: 20px;
    }
  }
}