@import "../../../styles/breakpoints";

.list {
  height: 480px;
  max-height: 50%;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;
  background-color: #fff;
  overflow-x: unset;
  overflow-y: auto;

  @media only screen and (min-width: $breakpoint-large) {
    margin-top: 150px;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    margin-top: 20px;
  }
}

.heading {
  font-size: 1.3em;
  font-weight: 500;
  @media only screen and (max-width: $breakpoint-medium) {
    margin-bottom: 10px;
  }
  color: var(--text-secondary-color);
}

.columnHeading {
  color: var(--text-secondary-color);
}

.container {
  display: flex;
  flex-flow: column nowrap;
}

.footer {
  margin-top: 10px;
  text-align: center;
}

.row {
  display: grid;
  grid-template-columns: calc(17% - 5px) calc(16% - 5px) calc(26% - 5px) calc(25% - 5px) calc(10% - 5px) calc(5% - 5px);
  gap: 5px 6px;
  grid-gap: 5px 6px;
  padding: 15px;

  @media only screen and (max-width: $breakpoint-medium) {
    display: none;
    grid-template-columns: 100%;
    text-align: center;
    gap: 5px 5px;
    grid-gap: 5px 5px;
  }
}