@import "../../styles/breakpoints.scss";

.emailInfoCard {
	height: auto;
	margin-top: 0;
	width: fit-content;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;

	.verificationLabel {
		margin: 0;
	}

	.wrapper {
		display: flex;
		gap: 0 5px;
		direction: ltr;
	}

	.submit.submit {
		margin: 0;
	}

	.resend {
		cursor: pointer;
		color: var(--text-secondary--color);
		transition: opacity 0.5s ease;
		opacity: 1;
		font-size: 15px;
	}
	
	.resend:hover {
		opacity: 0.6;
	}

	.error {
		margin: 0;
	}
}

.gap {
	gap: 20px;
}