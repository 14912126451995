@import "../../../styles/breakpoints";

.content {

  .button {
    border: none;
    border-radius: 50px;
    margin: 0 0 0 0.1em;
    cursor: pointer;
    font-size: 0.9em;
    padding: 0 12px;

    @media only screen and (max-width: $breakpoint-small) {
      font-size: 0.8em;
      padding: 0 4px;
    }

    &:first-child {
      margin: 0;
    }
  }

  [dir="rtl"] .button {
    margin: 0 0.1em 0 0;
  }

  .actionButton {
    padding: 0 20px;
  }

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @media only screen and (max-width: $breakpoint-medium) {
        justify-content: center;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }

  .title {
    margin: 0;
    font-size: 1em;
  }

  .hideSettings {
    visibility: hidden;
  }

  .showSettings {
    visibility: visible;
  }

  .cookieSettings {
    margin: 30px 0;

    @media only screen and (max-width: $breakpoint-small) {
      margin: 20px 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .cookieTitle {
    padding: 0 0 0 5px;
  }

  [dir="rtl"] .cookieTitle {
    padding: 0 5px 0 0;
  }

  .cookieText {
    margin: 0.5em 0;
  }

  .reactSwitchCheckbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .reactSwitchCheckbox:disabled {
    cursor: not-allowed;
  }

  .reactSwitchTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .reactSwitchLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 20px;
    border-radius: 25px;
    position: relative;
    transition: background-color .2s;
  }

	.enabled {
		background: #6AD600;
	}

	.disabled {
		background: grey;
	}

  .reactSwitchLabel .reactSwitchButton {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  .reactSwitchCheckbox:checked + .reactSwitchLabel .reactSwitchButton {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }

  .reactSwitchLabel:active .reactSwitchButton {
    width: 60px;
  }
}