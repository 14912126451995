@import "breakpoints";

.page {
  width: 80%;
  display: flex;
  position: relative;
  justify-content: center;

  @media only screen and (max-width: $breakpoint-large) {
    width: 90%;

    &:first-child {
      margin-top: 20px;
    }
  }
}

.component {
  width: 100%;
  height: auto;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background-color: var(--flow-step--background);

  @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) {
    width: 100%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    margin-left: 0;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

/**
  * Auth pages (Login, Register, PW reset, PW change, PW verify)
  */
.authContainer {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 100px;

  @media only screen and (min-width: $breakpoint-large) {
    margin-top: 150px;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    margin-top: 50px;
  }
}

.authForm {
  min-width: 150px;
  width: 350px;
  margin-top: auto;
  text-align: right;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.authLabel {
  display: block;
  color: var(--color-secondary);
  font-size: 15px;
  font-weight: 300;
  margin: 5px 0;
}

.authLinks {
  text-align: center;
  margin: 20px 0;
  cursor: default;

	span {
		margin-right: 5px;
	}
}

@mixin flex {
  display: flex;
  gap: 10px;
  min-width: 500px;
  align-items: center;
  margin: 0 0 0 20px;
  justify-content: space-between;

  @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) {
    margin: 0;
    min-width: 100%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    min-width: 100%;
    margin: 0;
    flex-direction: column;
    justify-content: space-around;
  }
}

.completeTitle {
  color: var(--flow-step-completed-heading--color);
  font-weight: bold;
}

.complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  color: var(--flow-step-completed-heading--color);

  &:first-child {
    align-items: flex-start;
  }

  &:last-child {
    padding-right: 0;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    margin: 10px 0;
    text-align: center;
    width: auto;

    > div {
      > div {
        text-align: center !important;
      }
    }
  }
}

.fixedDirection {
  text-align: left;
  direction: ltr;
}

[dir="rtl"] .fixedDirection {
  text-align: right;
  direction: ltr;
}

.complete-flex-align-center {
  display: grid;
  grid-template-columns: 1fr 720px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) {
    grid-template-columns: 100%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    flex-flow: column wrap;
    grid-template-columns: 100%;
  }
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 5px;
    color: var(--color-secondary);
  }
}

.flex-align-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  @media only screen and (max-width: $breakpoint-medium) {
    flex-direction: column;
    margin-left: 0;
    width: 100%;
  }
}

.submit-button-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}



.inline-complete-edit-button-wrapper {
  align-self: center;
}

[dir="rtl"] .complete-edit-button-wrapper {
  @media only screen and (max-width: $breakpoint-720p) {
    margin: 0 auto 0 0;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    margin: auto;
  }
}

.submit {
  background: var(--flow-step-submit-button--color);
}

.edit {
  background: var(--flow-step-edit-button--color);
  @media only screen and (max-width: $breakpoint-medium) {
    margin-right: 0;
  }
}

.show-small {
  display: none;
  @media only screen and (max-width: $breakpoint-medium) {
    display: unset;
  }
}

.checkbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    box-sizing: border-box;
  }

  input[type="checkbox"]:checked + label::before {
    opacity: 1;
  }

  input[type="checkbox"]:checked + label::after {
    border: var(--checkbox-checked--border);
    background-color: var(--checkbox-checked--color);
  }

  label::before {
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    border-color: transparent #fff #fff transparent;
    border-style: solid;
    border-width: 1px 2px 2px 1px;
    border-radius: 0 2px 1px 2px;
    content: "";
    height: 11px;
    left: 1px;
    right: auto;
    opacity: 0;
    position: absolute;
    transform: rotate(37deg);
    transform-origin: 100% 100%;
    transition: opacity 0.2s ease-out;
    width: 6px;
    z-index: 1;
    @media only screen and (max-width: $breakpoint-medium) {
      left: 2.5px;
      right: auto;
    }
  }

  label {
    position: relative;
    padding: 0 0 0 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 200;
    -webkit-user-select: none;
    user-select: none;
    @media only screen and (max-width: $breakpoint-medium) {
      padding: 0 0 0 30px;
    }
  }

  label:last-child {
    @media only screen and (max-width: $breakpoint-medium) {
      margin: 10px 0;
    }
  }

  label:focus {
    &::after {
      border: var(--flow-step-checkbox-checked--border);
      box-shadow: 0 0 0 2px #99c2ff;
    }
  }

  label:hover {
    &::after {
      box-shadow: 0 0 1px 1px #b9c4c9;
    }
  }

  label::after {
    content: "";
    position: absolute;
    left: 0;
    right: auto;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #b9c4c9;
    z-index: 0;
    transition: all 0.15s ease-out;
    @media only screen and (max-width: $breakpoint-medium) {
      width: 20px;
      height: 20px;
    }
  }

  @media only screen and (max-width: $breakpoint-medium) {
    width: 100%;
    margin-top: 5px;
  }
}

[dir="rtl"] .checkbox {

  label::before {
    right: 9px;
    left: auto;
    @media only screen and (max-width: $breakpoint-small) {
      left: auto;
      right: 11px;
    }
  }

  label {
    padding: 0 24px 0 0;
    @media only screen and (max-width: $breakpoint-small) {
      padding: 0 30px 0 0;
    }
  }

  label::after {
    position: absolute;
    left: auto;
    right: 0;
  }
}

.cardText {
  font-weight: 400;
  text-align: center;
  color: var(--color-dark)
}

.membershipNumber {
  font-weight: bold;
  color: var(--color-dark);
}

.input {
  border: var(--input--border);
  background-color: var(--input--background);
}

.link {
  background: none;
  text-decoration: none;
  height: auto;
  color: var(--color-secondary);

  &:hover {
    opacity: 0.7;
  }

  &:visited &:active {
    color: var(--color-secondary);
  }
}


.errorWithTitle {
  background: #d91a1a;
  border-radius: 20px;
  color: var(--color-footer-text);
  width: auto;

  > div {

    &:first-child {
      font-size: 1.2em;
    }

    color: var(--color-footer-text);
  }
}