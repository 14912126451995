@import "src/styles/breakpoints";

.container {
  > header {
    font-weight: 400;
    position: relative;

    @media only screen and (max-width: $breakpoint-large) {
      width: 50%;
    }
    @media only screen and (max-width: $breakpoint-medium) {
      width: 100%;
      text-align: center;
    }

    h2 {
      font-weight: 400;
      margin: 0 0 20px;
    }

    h4 {
      font-weight: 400;

      margin-bottom: 20px;
    }
  }
}