@import "../../../styles/breakpoints";

$maxButtonHeight: 40px;

.button {
  text-transform: uppercase;
  padding: 12px 30px;
  margin-left: auto;
  border: none;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  max-height: $maxButtonHeight;
  border-radius: 50px;
  transition: all 0.3s ease-out;
  align-self: center;


  &:disabled {
    background-color: #dcdcdc;
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    filter: brightness(115%);
  }
}

[dir="rtl"] .button {
  margin-left: 0;
  margin-right: auto;
}

.button-disabled {
  background-color: #dcdcdc;
  margin: auto;
}
