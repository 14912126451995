@import "../../../../../../styles/breakpoints";

.nameInputContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;

  div {
    flex-basis: 47%;
  }
}

.ltr {
  direction: ltr;
}

.checkbox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    box-sizing: border-box;
  }

  input[type="checkbox"]:checked + label::before {
    opacity: 1;
  }

  input[type="checkbox"]:checked + label::after {
    border: 1px solid #06f;
    background-color: #06f;
  }

  label::before {
    border-bottom: 1px solid transparent;
    border-right: 1px solid transparent;
    border-color: transparent #fff #fff transparent;
    border-style: solid;
    border-width: 1px 2px 2px 1px;
    border-radius: 0 2px 1px 2px;
    content: "";
    height: 11px;
    left: 1px;
    opacity: 0;
    position: absolute;
    transform: rotate(37deg);
    transform-origin: 100% 100%;
    transition: opacity 0.2s ease-out;
    width: 6px;
    z-index: 1;
    @media only screen and (max-width: $breakpoint-small) {
      left: 2.5px;
    }
  }

  label {
    position: relative;
    padding-left: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 200;
    -webkit-user-select: none;
    user-select: none;
    @media only screen and (max-width: $breakpoint-small) {
      padding-left: 30px;
    }
  }

  label:focus {
    &::after {
      border: 1px solid #06f;
      box-shadow: 0 0 0 2px #99c2ff;
    }
  }

  label:hover {
    &::after {
      box-shadow: 0 0 1px 1px #b9c4c9;
    }
  }

  label::after {
    content: "";
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #b9c4c9;
    z-index: 0;
    transition: all 0.15s ease-out;
    @media only screen and (max-width: $breakpoint-small) {
      width: 20px;
      height: 20px;
    }
  }

  @media only screen and (max-width: $breakpoint-small) {
    width: 100%;
    margin-top: 25px;
  }
}

[dir="rtl"] .checkbox {
  flex-direction: row;
}

.passwordContainer {
  margin-top: 10px;
}

.disabled {
  cursor: not-allowed;
}

.disabled input {
  pointer-events: none;
}

.loginContainer {
  display: flex;
  flex-direction: column;
}

.contact {
  margin-bottom: 1em;

  .dropdown {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 100%;
    width: 100%;
    z-index: 2;

    @media only screen and (max-width: $breakpoint-medium) {
      position: static;
      padding-left: 10px;
    }

    :global .container {
      display: flex;
      align-items: center;
      border-radius: 30px;
      padding: 10px;
      height: 2.5em;
      width: inherit;

      @media only screen and (max-width: $breakpoint-medium) {
        padding: 0;
        border-radius: 50%;
        height: 3em;
      }
    }

    :global .label {
      margin: 0 0 0 5px;
      text-transform: uppercase;
      color: var(--flow-dropdown-label--color);
    }

    [dir="rtl"] :global .label {
      margin: 0 5px 0 0;
    }

    :global .list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      top: 50px;
      border-radius: 0 0 30px 30px;
      overflow: hidden;
      max-height: 0;
      width: 100%;
      transform: translate(-50%);
      left: 50%;
      box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 5%);
      transition: all 0.2s ease;
      min-width: 200px;
      background: var(--flow-dropdown--background);
      color: var(--flow-dropdown--color);

      @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) and (max-width: $breakpoint-large) {
        min-width: 120px;
      }

      @media only screen and (max-width: $breakpoint-medium) {
        left: 0;
        width: 100%;
        transform: initial;
        border-radius: 0 0 30px 30px;
        max-height: 0;
        box-shadow: 0 0 0.5rem 0 rgb(0 0 0 / 5%);
        transition: all 0.2s ease-in;
      }
    }

    :global .listActive {
      padding: 8px;
      transition: all 0.5s ease-in !important;
      max-height: 500px !important;
      @media only screen and (max-width: $breakpoint-medium) {
        padding: 0;
      }
    }

    :global .option {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      text-transform: uppercase;
      border-bottom: 0.5px solid rgba(211, 211, 211, 0.8);
      padding: 10px;

      &:last-child {
        border-bottom: none;
      }

      @media only screen and (max-width: $breakpoint-medium) {
        text-decoration: none;
        padding: 10px;
      }
    }
  }
}

.icon {
  display: flex;
  height: 1.5em;
  width: 1.5em;
  align-items: center;
  margin: auto;

  > svg {
    height: 100%;
    width: 100%;
    stroke: var(--flow-dropdown-icon--fill);
  }
}