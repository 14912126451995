@import "../../../../styles/breakpoints";

[data-scrolling] {
  .list {
    .item {
      background-color: var(--header__scrolling-dropdown--background);
      color: var(--header__scrolling-dropdown--color);
    }
  }
}

.list {
  transition: all 0.2s ease-in;

  .item {
    background-color: var(--header-dropdown--background);
    color: var(--header-dropdown--color);
  }

  .option {
    display: block;
    width: 100%;
    cursor: pointer;
    padding: 0 4px;
    border-bottom: 0.2px solid #f2f2f2;
    text-decoration: none;
    text-transform: uppercase;

    &:last-child {
      border-bottom: none;
    }
  }
}

