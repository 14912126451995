@import "../../../styles/breakpoints";

[data-scrolling] {
  .hamburger {
    .bar {
      background: var(--color-header-navigation_scrolling-input-text);
      color: var(--color-header-navigation_scrolling-input-background);
    }
  }
}

.hamburger {
  display: block;
  cursor: pointer;
  z-index: 10;
  box-sizing: border-box;

  @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) {
    height: calc(2.5em - 20px);
  }

  .bar {
    display: block;
    width: 20px;
    height: 2px;
    margin: 4px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    color: var(--color-header-navigation_initial-input-background);
    background: var(--color-header-navigation_initial-input-text);
  }

  &.active {
    @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) {
      height: calc(2.5em - 18px);
    }

    .bar {
      @media only screen and (max-width: $breakpoint-medium) {
        width: 22px;
        height: 3px;
        margin: 5px auto;
      }

      &:nth-child(1) {
        @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) {
          transform: translateY(7px) rotate(45deg);
        }
        @media only screen and (max-width: $breakpoint-medium) {
          transform: translateY(8px) rotate(45deg);
        }
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        @media only screen and (min-width: calc(#{$breakpoint-medium} + 1px)) {
          transform: translateY(-5px) rotate(-45deg);
        }
        @media only screen and (max-width: $breakpoint-medium) {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
  }
}
