@import "../../../styles/breakpoints";

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
  width: inherit;
  outline: 0;
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 500;
}

.modal {
  z-index: 100;
  position: relative;
  margin: auto;
  border-radius: 20px;
  overflow: auto;
  background: var(--modal--background);
}

.header {
  border-radius: 8px 8px 0 0;
  display: flex;
	position: relative;
  justify-content: space-between;
  padding: 20px 20px 10px;

}

.headerText {
	font-size: 1.2em;
	font-weight: 700;
  align-self: center;
  color: var(--modal-heading--color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.closeButton {
  font-size: 0.8rem;
  border: none;
  border-radius: 3px;
  margin-left: 0.5rem;
  background: none;

  &:hover {
    cursor: pointer;
  }
}

.content {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
  min-width: 260px;
  max-width: 50vh;
  padding: 0 5px;
  margin: 0 15px 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  scrollbar-color: var(--color-secondary) var(--color-primary);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    display: block;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--color-primary);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-secondary);
    border-radius: 10px;
  }
}

.languageDropdownList {
  color: var(--language-dropdown--color);
  background: var(--language-dropdown-list--background);
}