@import "src/styles/Shared.module";
@import "src/styles/breakpoints";

.heading {
  margin: 0 0 20px;
  font-weight: 400;
}

@mixin flexContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  gap: 20px;
}

.container {
  @extend .page;
  @include flexContainer;

  max-width: $max-content-width;
  margin: 0 auto;

  h2 {
    color: var(--flow-step-heading--color);
  }

  h4 {
    color: var(--flow-step-subheading--color)
  }

  @media only screen and (min-width: $breakpoint-medium) {
    margin-top: -50px;
  }

  @media only screen and (max-width: $breakpoint-medium) {
    margin-top: 0;
  }

  .steps {
    @include flexContainer;

    > div:last-child > div:nth-child(2) {
      display: none !important;
    }
  }
}

.radio-input-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.radio-input {
  display: flex;
  margin-right: 30px;
}

.radio-input-text {
  margin-left: 5px;
}

