@import "../../../styles/breakpoints";

.wrapper {
  position: relative;

  .controls {
    padding: 0 30px;
  }

  .container {
    position: relative;
  }

  .map {
    height: 350px;
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    outline: none;

    @media only screen and (max-width: $breakpoint-medium) {
      width: 100%;
    }

    > * {
      height: 100%;
    }
  }

	.disabled {
		cursor: not-allowed;
		> * {
			cursor: not-allowed;
		}
	}

	.loadingOverlay {
		@extend .disabled;

		position: absolute;
		width: 100%; 
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(1, 1, 1, 0.3);
		z-index: 2; 
	}
  .error {
    padding: 5px 30px;
  }
}

.redZone {
	cursor: not-allowed;
}

.mapContainer {
  position: relative;
  z-index: 1;
}