@import "../../../styles/breakpoints";

.account {
  flex: 1;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  overflow-x: unset;
  overflow-y: auto;
  padding: 30px;
}

.content {
  width: 100%;
  overflow-x: unset;
  overflow-y: auto;
}

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 10px 10px;
  grid-gap: 10px 10px;
  height: 10%;
  margin-bottom: 20px;
}

.heading {
  font-size: 1.3em;
  font-weight: 500;
  color: var(--text-secondary--color);
}

.footer {
  margin-top: 25px;
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  gap: 10px 20px;
  grid-gap: 10px 20px;

  @media only screen and (max-width: $breakpoint-smallest) {
    grid-template-columns: 100%;
    text-align: center;
  }
}

.leftAligned {
  text-align: right;
  margin: auto 0 auto auto;
}

[dir="rtl"] .leftAligned {
  text-align: left;
}

.icon {
  width: 25px;
  cursor: pointer;
  fill: var(--input-icon--color);
}

.row {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(95px, 1fr));

  @media only screen and (max-width: $breakpoint-smallest) {
    grid-template-columns: 100%;
    text-align: left;
  }

  &:first-child {
    margin-top: 10px;
  }
}

[dir="rtl"] .row {
  @media only screen and (max-width: $breakpoint-smallest) {
    text-align: right;
  }
}

.column {
  color: #98A4A8;

  &:first-child {
    font-weight: bold;
  }
}

.error {
  color: red;
  text-align: right;
  margin: 0;
  font-weight: 400;
}

[dir="rtl"] .error {
  text-align: left;
}