@import "../../../../styles/breakpoints";

.cityPickupInfoBox {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 20px auto 0;

  @media only screen and (max-width: $breakpoint-medium) {
    width: 90%;
  }

  @media only screen and (max-width: $breakpoint-small) {
    width: 100%;
  }
}

.infoIcon {
  margin: 0 auto;
  justify-content: center;
  display: flex;
  > svg {
    height: 50px;
    width: 50px;
    fill: var(--color-dark) !important;
  }
}

.cityPickupInfo {
  font-size: 1.1em;
  font-weight: 400;
  color: var(--color-dark);
}

h4.cityPickupInfo {
  margin: 10px auto 0;
}