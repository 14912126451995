@import "../../../styles/breakpoints";

.date-time-container {
  font-size: 15px;
  font-weight: 300;
  flex-basis: 47%;
  color: var(--input-datetime-text--color);
  @media only screen and (max-width: $breakpoint-large) {
    flex-basis: 100%;
  }
}

.date-time {
  width: 100%;
  position: relative;
}

.dateInput {
  box-sizing: border-box;
  height: 50px;
  border-radius: 3px;
  padding: 10px 40px 10px 40px !important;
  width: 100%;
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 2px;
  text-align: left;
}

[dir="rtl"] .dateInput {
  text-align: right;
}

.date-icon {
  position: absolute;
  width: 22px;
  top: 0.8rem;
  left: 10px;
  pointer-events: none;
  z-index: 1;

  > svg {
    fill: var(--input-icon--color) !important;
  }
}

[dir="rtl"] .date-icon {
  left: auto;
  right: 15px;
}

[dir="rtl"] .caret {
  right: auto;
  left: 15px;
}

.chevron-icon {
  width: 22px;
  position: absolute;
  right: 15px;
  top: 0.9rem;
  pointer-events: none;
}

[dir="rtl"] .chevron-icon {
  right: auto;
  left: 15px;
}