.title {
  margin: 0 0 1em;
  font-size: 1.2em;
}

.list {
	padding: 0 20px;
}

.item {
	padding: 1em 0;
}