@import "../../../../styles/breakpoints";

.container {
  z-index: 10;
  position: absolute;
  left: 50px;
  top: 65px;
  width: 40%;

  @media only screen and (max-width: $breakpoint-large) {
    width: 50%;
  }
  @media only screen and (max-width: $breakpoint-medium) {
    width: 60%;
  }
  @media only screen and (max-width: $breakpoint-small) {
    width: calc(100% - (20px));
  }
}

[dir="rtl"] .container {
  right: 10px;
  left: initial;
}

.location-icon {
  width: 22px;
  height: 28px;
  position: absolute;
  left: 13px;
  right: 0;
  top: 0.7rem;
  pointer-events: none;
	svg {
		fill: var(--input-icon--color);
	}
}

[dir="rtl"] .location-icon {
  right: 13px;
  left: 0;
}

.chevron-icon {
  width: 22px;
  position: absolute;
  right: 15px;
  top: 0.9rem;
  pointer-events: none;
}

.hubSelect {
  display: block;
  position: relative;
}

.hubInput {
  padding: 1em !important;
}

.addressSwitch {
	margin-left: 30px;
}

[dir="rtl"] .addressSwitch {
	margin-right: 30px;
	margin-left: 0;
}