@import "src/styles/breakpoints";
@import "src/styles/Shared.module";

.heading {
  margin: 0 0 30px;
  font-weight: 400;
}

.container {
  grid-template-columns: 1fr;
}