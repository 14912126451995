@import "src/styles/breakpoints";

.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  z-index: 9;

  height: 140px;
  width: 80%;
  max-width: calc(#{$max-content-width * .8});

  @media only screen and (max-width: $breakpoint-large) {
    width: 90%;
    max-width: calc(#{$max-content-width * .9});
  }

  .floater {
    width: inherit;
    max-width: calc(#{$max-content-width * .8});

    @media only screen and (max-width: $breakpoint-large) {
      max-width: calc(#{$max-content-width * .9});
    }

    &.fixed {
      position: fixed;
      bottom: 0;
    }

    &.relative {
      position: relative;
      width: 100%;
    }
  }
}

.priceOverview {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0 46px;
  background: var(--price-overview--background);
  color: var(--price-overview--color);
  height: 100px;
  bottom: 20px;
  margin: 40px auto 10px;
  border-radius: var(--price-overview--border-radius);
  width: 100%;
  
  @media only screen and (max-width: $breakpoint-small) {
    justify-content: center;
  }

  .flexContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .service {
    font-weight: bold;
    font-size: 22px;
    align-self: center;
    @media only screen and (max-width: $breakpoint-small) {
      display: none;
    }
  }

  .total {
    font-weight: bold;
    font-size: 22px;
    align-self: center;
    margin: 0 30px;
  }

  .price {
    font-weight: bold;
    font-size: 28px;
    align-self: center;
  }

}
