@font-face {
  font-family: "Changa";
  src: url("./resources/fonts/Changa-Bold/Changa-Bold.woff") format("woff"),
    url("./resources/fonts/Changa-Bold/Changa-Bold.woff2") format("woff2"),
    url("./resources/fonts/Changa-Bold/Changa-Bold.svg#Changa-Bold") format("svg"),
    url("./resources/fonts/Changa-Bold/Changa-Bold.eot"),
    url("./resources/fonts/Changa-Bold/Changa-Bold.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Changa-Bold/Changa-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Changa";
  src: url("./resources/fonts/Changa-Regular/Changa-Regular.woff") format("woff"),
    url("./resources/fonts/Changa-Regular/Changa-Regular.woff2") format("woff2"),
    url("./resources/fonts/Changa-Regular/Changa-Regular.svg#Changa-Regular") format("svg"),
    url("./resources/fonts/Changa-Regular/Changa-Regular.eot"),
    url("./resources/fonts/Changa-Regular/Changa-Regular.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Changa-Regular/Changa-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Kufam";
  src: url("./resources/fonts/Kufam-Bold/Kufam-Bold.woff") format("woff"),
    url("./resources/fonts/Kufam-Bold/Kufam-Bold.woff2") format("woff2"),
    url("./resources/fonts/Kufam-Bold/Kufam-Bold.svg#Kufam-Bold") format("svg"),
    url("./resources/fonts/Kufam-Bold/Kufam-Bold.eot"),
    url("./resources/fonts/Kufam-Bold/Kufam-Bold.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Kufam-Bold/Kufam-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Kufam";
  src: url("./resources/fonts/Kufam-Regular/Kufam-Regular.woff") format("woff"),
    url("./resources/fonts/Kufam-Regular/Kufam-Regular.woff2") format("woff2"),
    url("./resources/fonts/Kufam-Regular/Kufam-Regular.svg#Kufam-Regular") format("svg"),
    url("./resources/fonts/Kufam-Regular/Kufam-Regular.eot"),
    url("./resources/fonts/Kufam-Regular/Kufam-Regular.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Kufam-Regular/Kufam-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  src: url("./resources/fonts/Quicksand-Bold/Quicksand-Bold.woff") format("woff"),
    url("./resources/fonts/Quicksand-Bold/Quicksand-Bold.woff2") format("woff2"),
    url("./resources/fonts/Quicksand-Bold/Quicksand-Bold.svg#Quicksand-Bold") format("svg"),
    url("./resources/fonts/Quicksand-Bold/Quicksand-Bold.eot"),
    url("./resources/fonts/Quicksand-Bold/Quicksand-Bold.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Quicksand-Bold/Quicksand-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Quicksand";
  src: url("./resources/fonts/Quicksand-Regular/Quicksand-Regular.woff") format("woff"),
    url("./resources/fonts/Quicksand-Regular/Quicksand-Regular.woff2") format("woff2"),
    url("./resources/fonts/Quicksand-Regular/Quicksand-Regular.svg#Quicksand-Regular") format("svg"),
    url("./resources/fonts/Quicksand-Regular/Quicksand-Regular.eot"),
    url("./resources/fonts/Quicksand-Regular/Quicksand-Regular.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Quicksand-Regular/Quicksand-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo";
  src: url("./resources/fonts/Cairo-Bold/Cairo-Bold.woff") format("woff"),
    url("./resources/fonts/Cairo-Bold/Cairo-Bold.woff2") format("woff2"),
    url("./resources/fonts/Cairo-Bold/Cairo-Bold.svg#Cairo-Bold") format("svg"),
    url("./resources/fonts/Cairo-Bold/Cairo-Bold.eot"),
    url("./resources/fonts/Cairo-Bold/Cairo-Bold.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Cairo-Bold/Cairo-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Cairo";
  src: url("./resources/fonts/Cairo-Regular/Cairo-Regular.woff") format("woff"),
    url("./resources/fonts/Cairo-Regular/Cairo-Regular.woff2") format("woff2"),
    url("./resources/fonts/Cairo-Regular/Cairo-Regular.svg#Cairo-Regular") format("svg"),
    url("./resources/fonts/Cairo-Regular/Cairo-Regular.eot"),
    url("./resources/fonts/Cairo-Regular/Cairo-Regular.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Cairo-Regular/Cairo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal";
  src: url("./resources/fonts/Tajawal-Bold/Tajawal-Bold.woff") format("woff"),
    url("./resources/fonts/Tajawal-Bold/Tajawal-Bold.woff2") format("woff2"),
    url("./resources/fonts/Tajawal-Bold/Tajawal-Bold.svg#Tajawal-Bold") format("svg"),
    url("./resources/fonts/Tajawal-Bold/Tajawal-Bold.eot"),
    url("./resources/fonts/Tajawal-Bold/Tajawal-Bold.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Tajawal-Bold/Tajawal-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Tajawal";
  src: url("./resources/fonts/Tajawal-Regular/Tajawal-Regular.woff") format("woff"),
    url("./resources/fonts/Tajawal-Regular/Tajawal-Regular.woff2") format("woff2"),
    url("./resources/fonts/Tajawal-Regular/Tajawal-Regular.svg#Tajawal-Regular") format("svg"),
    url("./resources/fonts/Tajawal-Regular/Tajawal-Regular.eot"),
    url("./resources/fonts/Tajawal-Regular/Tajawal-Regular.eot?#iefix") format("embedded-opentype"),
    url("./resources/fonts/Tajawal-Regular/Tajawal-Regular.ttf") format("truetype");
}
@font-face {
	font-family: 'Ambit';
	src: url('./resources/fonts/Ambit-Bold/AmbitTRIAL-Bold.otf') format('opentype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Ambit';
	src: url('./resources/fonts/Ambit-Regular/AmbitTRIAL-Black.otf') format('opentype');
	font-style: normal;
}

@font-face {
	font-family: 'TUI-Type';
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Rg.eot');
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Rg.eot?#iefix') format('embedded-opentype'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Rg.woff2') format('woff2'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Rg.woff') format('woff'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Rg.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TUI-Type';
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.eot');
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.eot?#iefix') format('embedded-opentype'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.woff2') format('woff2'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.woff') format('woff'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'TUI-Type';
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_It.eot');
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_It.eot?#iefix') format('embedded-opentype'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_It.woff2') format('woff2'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_It.woff') format('woff'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_It.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'TUI-Type';
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_BdIt.eot');
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_BdIt.eot?#iefix') format('embedded-opentype'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_BdIt.woff2') format('woff2'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_BdIt.woff') format('woff'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_BdIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'TUI-Type';
	src: url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Rg.eot');
	src: url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Rg.eot?#iefix') format('embedded-opentype'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Rg.woff2') format('woff2'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Rg.woff') format('woff'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Rg.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TUI-Type';
	src: url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Bd.eot');
	src: url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Bd.eot?#iefix') format('embedded-opentype'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Bd.woff2') format('woff2'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Bd.woff') format('woff'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_CYRL_W_Bd.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'TUI-Type';
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.eot');
	src: url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.eot?#iefix') format('embedded-opentype'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.woff2') format('woff2'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.woff') format('woff'),
			 url('./resources/fonts/TUI-Type/TUITypeLt_LATN_W_Bd.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

* {
  margin: 0;
  font-family: var(--text--font), sans-serif;
}

/*Won't enable it yet, waiting for Arabic font approval*/

/*[dir="rtl"] * {*/
/*  font-family: 'Tajawal', sans-serif;*/
/*  line-height: 1.5;*/
/*}*/

a:focus {
  background: none;
  border-style: none;
  border-width: 0;
}

:root {
	--h1--font: "Quicksand", sans-serif;
	--text--font: "Quicksand", sans-serif;
  --h1--color: #fff;
  --h1--size: 3vw;
  --text--color: #000;
  --text-secondary--color: #0085bb;
  --page--background: #fff;
  --button-primary--background: linear-gradient(270deg, #FF9558, #FF6D53);
  --button-primary--color: #fff;
  --button-secondary--background: linear-gradient(270deg, #FF9558, #FF6D53);
  --button-secondary--color: #fff;
  --header--height: 400px;
  --header--background: url('./resources/images/bagpoint-background.jpg') center center / cover no-repeat;
  --header-text--color: #fff;
  --header-navigation-logo--background: #fff;
  --header-navigation--background: transparent;
  --header-navigation-logo--height: 50px;
  --header-navigation-logo--width: 200px;
  --header-navigation-logo-fill: #d40e14;
  --header-dropdown--background: #fff;
  --header-dropdown--color: #0085bb;
  --header-navigation--overlay: linear-gradient(90deg, rgb(0, 158, 255) 0%, rgba(53, 195, 255, 0.75) 100%);
  --header-language-dropdown--background: #0085bb;
  --header__scrolling-navigation--background: rgba(249, 249, 249, 0.85);
  --header__scrolling-text--color: #0085bb;
  --header__scrolling-dropdown--color: #fff;
  --header__scrolling-dropdown--background: #0085bb;
  --header__scrolling-navigation-logo--background: #0085bb;
  --header__scrolling-navigation-logo--height: 100px;
  --header__scrolling-navigation-input--background: blue;
  --header__scrolling-navigation-overlay--background: transparent;
  --language-dropdown-list--background: #0085bb;
  --language-dropdown--color: #fff;
  --footer--background: linear-gradient(90deg, rgba(0,158,255,1) 0%, rgba(62,196,253,1) 100%);
  --footer-overlay--background: transparent;
  --footer-text--color: #fff;
  --footer-trademark--opacity: 0.5;
  --footer-logo--color: #fff;
  --flow--gap: 20px;
  --spinner--background: #0085bb;
  --flow-progress-bar-icon__submitted--background: #0085BB;
  --flow-progress-bar-icon__submitted--color: #fff;
  --flow-progress-bar-line__submitted--border: 4px solid #0085BB;
  --flow-progress-bar-icon__active--background: linear-gradient(270deg, rgb(255, 149, 88), rgb(255, 109, 83));
  --flow-progress-bar-icon__active--color: #fff;
  --flow-progress-bar-icon__inactive--background: #fff;
  --flow-progress-bar-line__inactive--border: 4px solid #e4eded;
  --flow-progress-bar-line__inactive--color: #000;
  --flow-step--background: #fff;
  --flow-step--color: #000;
  --flow-step--border-radius: 20px;
  --flow-step-heading--color: #000;
  --flow-step-subheading--color: #000;
  --flow-step-completed-heading--color: #000;
  --flow-step-submit-button--background: linear-gradient(270deg, rgb(255, 149, 88), rgb(255, 109, 83));
  --flow-step-submit-button--color: #fff;
  --flow-step-submit-button--border-radius: 50px;
  --flow-step-edit-button--background: linear-gradient(270deg, rgb(255, 149, 88), rgb(255, 109, 83));
  --flow-step-edit-button--color: #fff;
  --flow-step-edit-button--border-radius: 50px;
  --flow-error--color: #d91a1a;
  --flow-dropdown--color: #0085bb;
  --flow-dropdown-label--color: #0085bb;
  --flow-dropdown--background: #fff;
  --flow-dropdown-icon--fill: #0085bb;
  --information--background: #000;
  --tooltip--color: linear-gradient(270deg, rgb(255, 149, 88), rgb(255, 109, 83));
  --checkbox-label--color: #000;
  --checkbox-label-link--color: blue;
  --checkbox-checked--color: #06f;
  --checkbox-checked--border: 1px solid #06f;
  --toggle--border: 2px solid #0085BB;
  --toggle-handle--background: #0085BB;
  --input--background: #f9f9f9;
  --input-disabled--background: #f9f9f9;
  --input-label--color: #0085bb;
  --input--border: 0.5px solid #0085bb;
  --input-focus--border: 0.5px solid #0085bb;
  --input-icon--color: #0085bb;
  --input-dropdown-list--border: 1px solid #0085bb;
  --input-dropdown-option--background: #f9f9f9;
  --input-datetime-text--color: #0085bb;
  --flow-service-selector--border-radius: 20px;
  --flow-service-selector__active-icon--background: #0085bb;
  --flow-service-selector__active--border: 1px solid red;
  --flow-service-selector__active-text--color: #0085bb;
  --flow-service-selector__active--background: #fff;
  --flow-service-selector__inactive-icon--background: #5C5C5C;
  --flow-service-selector__inactive--border: #000;
  --flow-service-selector__inactive-text--color: #5C5C5C;
  --flow-service-selector__inactive--background: #fff;
  --flow-payment-error--background: #f9f9f9;
  --flow-payment-error--border-radius: 4px; 
  --flow-payment-error--border: 1px solid #d91a1a; 
  --flow-payment-error--color: #d91a1a; 
  --info-card--background: #f9f9f9;
  --info-card--color: #000;
  --info-card-heading--color: #0085BB;
  --info-card-icon--fill: #0085BB;
  --info-card-error--background: #d91a1a;
  --info-card-error--color: #fff;
  --info-card-error-heading--color: #fff;
  --info-card-error-icon--fill: #fff;
  --modal--background: #fff;
  --modal-heading--color: #d3d3d3;
  --map-info-window-name--background: linear-gradient(270deg, rgb(255, 149, 88), rgb(255, 109, 83));
  --map-info-window-description--color: #0085BB;
  --summary-step-card--background: #fff;
  --summary-date_time--color: #0085BB;
  --price-overview--background: linear-gradient(270deg, #FF9558, #FF6D53);
  --price-overview--color: #fff;
  --price-overview--border-radius: 50px;
  --payment-method--background: #fff;
  --payment-method--color: #0085BB;
}
